import { Button, Icons, Row, Space, Themes, Typography } from '@jcm/design-system';
import { Modal } from 'antd';
import Keycloak, { KeycloakConfig, KeycloakLoginOptions, KeycloakProfile } from 'keycloak-js';
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useInterval } from 'usehooks-ts';

import { useGlobalConfigFile } from 'hooks';

import { ThemeContextProvider } from './ThemeContext';

export interface IAuthUser {
	sub: string;
	preferred_username: string;

	email?: string;
	email_verified?: boolean;

	name?: string;
	given_name: string;
	family_name: string;
}

export interface IAuthProfile extends KeycloakProfile {}

export interface IKeycloakContext {
	isLoading: boolean;
	isAuthenticated?: boolean;

	user?: IAuthUser;
	profile?: IAuthProfile;
	idToken?: string;
	accessToken?: string;
	tokenExpires?: Date;
	isAdmin?: boolean | null;

	login: (options?: KeycloakLoginOptions) => Promise<void>;
	logout: () => Promise<void>;
	checkSessionExpiration: () => void;
}

// eslint-disable-next-line
const KeycloakContext = createContext<IKeycloakContext>(undefined!);

export const useKeycloakContext = () => useContext(KeycloakContext);

type ModalSessaoExpiradaProps = { isOpen: boolean; onOk: () => void };
const ModalSessaoExpirada: React.FC<ModalSessaoExpiradaProps> = ({ isOpen, onOk }) => {
	return (
		// Modal não acompanha o tema da página
		<ThemeContextProvider overrides={{ dark: Themes.getLightTheme() }}>
			<Modal
				open={isOpen}
				onOk={onOk}
				closable={false}
				maskClosable={false}
				keyboard={false}
				title={
					<Space>
						<Typography.Title variant='error' size='large'>
							<Icons.Error variant='outlined' />
						</Typography.Title>
						<Typography.Title variant='default' size='medium'>
							Sua sessão expirou!
						</Typography.Title>
					</Space>
				}
				footer={
					<Row style={{ justifyContent: 'end' }}>
						<Button variant='default' type='filled' onClick={onOk}>
							Atualizar
						</Button>
					</Row>
				}
				children={
					<Typography.Body size='medium' variant='default' style={{ marginLeft: '1rem' }}>
						Por favor, atualize a página para continuar.
					</Typography.Body>
				}
			/>
		</ThemeContextProvider>
	);
};

type Props = { children: React.ReactNode; admin?: boolean; isProduction?: boolean };
export const KeycloakContextProvider: React.FC<Props> = ({ children, admin, isProduction }) => {
	const [isModalOpen, setIsModalOpen] = useState(false);

	const { keycloakConfig: kcConfig } = useGlobalConfigFile();

	const keycloakConfig = useMemo(() => {
		const { url, devUrl, realm, participanteClientId, adminClientId } = kcConfig;

		const config: KeycloakConfig = {
			url: isProduction || process.env.NODE_ENV === 'production' ? url : devUrl,
			clientId: admin ? adminClientId : participanteClientId,
			realm: realm,
		};

		return config;
	}, [admin, isProduction, kcConfig]);

	const [isLoading, setIsLoading] = useState(true);
	const [user, setUser] = useState<IAuthUser>();
	const [profile, setProfile] = useState<IAuthProfile>();
	const [isAdmin, setIsAdmin] = useState<boolean | null>(null);

	const keycloak = useMemo(() => new Keycloak(keycloakConfig), [keycloakConfig]);
	const isAuthenticated = keycloak.authenticated;
	const idToken = keycloak.idToken;
	const accessToken = keycloak.token;

	const logout = useCallback(() => {
		setIsLoading(true);
		return keycloak.logout();
	}, [keycloak]);

	const login = useCallback((options?: KeycloakLoginOptions) => keycloak.login(options), [keycloak]);

	// Inicializa o keycloak
	const keycloakInit = useCallback(async () => {
		await keycloak.init({ onLoad: 'login-required' }).then(() => setIsLoading(false));
	}, [keycloak]);

	useEffect(() => {
		if (!isLoading) return;

		keycloakInit().catch((err) => console.error(`AuthContext: ${err?.message}`));
	}, [keycloakInit, isLoading]);

	// Carrega info do usuário
	const keycloakLoadUserInfo = useCallback(async () => {
		await keycloak.loadUserInfo().then((info) => setUser(info as IAuthUser));
	}, [keycloak]);

	useEffect(() => {
		if (isLoading || !isAuthenticated) return;

		keycloakLoadUserInfo().catch((err) => console.error(err));
	}, [keycloakLoadUserInfo, isLoading, isAuthenticated]);

	// Carrega perfil do usuário
	const keycloakLoadUserProfile = useCallback(async () => {
		const tokenParsed = keycloak.tokenParsed;
		const isAdminRoleValue = !tokenParsed
			? null
			: tokenParsed.role && tokenParsed.role[0] === 'admin-entidade'
				? true
				: false;

		setIsAdmin(isAdminRoleValue);
		await keycloak.loadUserProfile().then((profile) => setProfile(profile));
	}, [keycloak]);

	useEffect(() => {
		if (isLoading) return;

		keycloakLoadUserProfile().catch((err) => console.error(err));
	}, [keycloakLoadUserProfile, isLoading]);

	// Checa a sessão
	const checkSessionExpiration = useCallback(() => setIsModalOpen(keycloak.isTokenExpired()), [keycloak]);

	const useIntervalMs = !!user && !isModalOpen ? 0.1 * 60 * 1000 : null;
	useInterval(() => checkSessionExpiration(), useIntervalMs);

	const context = useMemo<IKeycloakContext>(() => {
		return {
			user,
			profile,
			isAuthenticated,
			isLoading,
			idToken,
			accessToken,
			login,
			logout,
			checkSessionExpiration,
			isAdmin,
		};
	}, [user, profile, isAuthenticated, isLoading, idToken, accessToken, login, logout, checkSessionExpiration, isAdmin]);

	return (
		<KeycloakContext.Provider value={context}>
			{isLoading ? null : (
				<>
					<ModalSessaoExpirada isOpen={isModalOpen} onOk={() => keycloak.login()} />
					{children}
				</>
			)}
		</KeycloakContext.Provider>
	);
};
