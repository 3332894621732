import { AxiosInstance } from 'axios';

/**
 * Classe base pras classes de API.
 *
 * Não instanciar!
 */
export class BaseApi {
	private api: AxiosInstance;
	private clienteId?: number | string;

	constructor(props: { api: AxiosInstance; clienteId?: number | string }) {
		this.api = props.api;
		this.clienteId = props.clienteId;

		return this;
	}

	/**
	 * @param url Url base.
	 * @returns Url tratado, com `clienteId` adicionado (caso necessário).
	 */
	private getRequestUrl = (url: string) => {
		// Caso clienteId seja definido, adicionamos à url
		const requestUrl = this.clienteId ? `/${this.clienteId}${url}` : url;

		return requestUrl;
	};

	/**
	 * Método que roda uma request (axios) com o verbo `GET`.
	 *
	 * @param url Endereço do recurso.
	 * @param params Parâmetros do corpo da requisição (pra uma pesquisa, por exemplo).
	 * @returns Promise que resolve para um valor `T`.
	 */
	protected getRequest = async <T>(url: string, params?: Record<string, unknown>): Promise<T> => {
		const requestUrl = this.getRequestUrl(url);
		const { data } = await this.api.get<T>(requestUrl, { params });

		return data;
	};

	/**
	 * Método que roda uma request (axios) com o verbo `POST`.
	 *
	 * @param url Endereço do recurso.
	 * @param payload Payload da requisição, com tipo `T`.
	 * @returns Promise que resolve para um valor `T`
	 */
	protected postRequest = async <T>(url: string, payload: T): Promise<T> => {
		const requestUrl = this.getRequestUrl(url);
		const { data } = await this.api.post<T>(requestUrl, payload);

		return data;
	};

	/**
	 * Método que roda uma request (axios) com o verbo `POST`.
	 *
	 * @param url Endereço do recurso.
	 * @param payload Payload da requisição, com tipo `TArgs`.
	 * @returns Promise que resolve para um valor `TReturn`
	 */
	protected postRequestWithReturn = async <TArgs, TReturn>(url: string, payload: TArgs): Promise<TReturn> => {
		const requestUrl = this.getRequestUrl(url);
		const { data } = await this.api.post<TReturn>(requestUrl, payload);

		return data;
	};

	/**
	 * Método que roda uma request (axios) com o verbo `PUT`.
	 *
	 * @param url Endereço do recurso.
	 * @param payload Payload da requisição, com tipo `T`.
	 * @returns Promise que resolve para um valor `T`
	 */
	protected putRequest = async <T>(url: string, payload: T): Promise<T> => {
		const requestUrl = this.getRequestUrl(url);
		const { data } = await this.api.put<T>(requestUrl, payload);

		return data;
	};
}
