import {
	IThemeContext as DSThemeContext,
	ThemeContextProvider as DSThemeContextProvider,
	Themes,
} from '@jcm/design-system';
import { ConfigProvider } from 'antd';
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useLocalStorage } from 'usehooks-ts';

import locale from 'antd/locale/pt_BR';
import 'dayjs/locale/pt-br';

import { useGlobalConfigFile } from 'hooks';

type ThemeVariant = 'light' | 'dark';

export interface IThemeContext {
	currentTheme: ThemeVariant;
	toggleTheme: () => void;
}

// eslint-disable-next-line
const ThemeContext = createContext<IThemeContext>(undefined!);

export const useThemeContext = () => useContext(ThemeContext);

type Props = {
	children: React.ReactNode;
	overrides?: { light?: Partial<DSThemeContext>; dark?: Partial<DSThemeContext> };
};
export const ThemeContextProvider: React.FC<Props> = ({ children, overrides }) => {
	const { themeConfig } = useGlobalConfigFile();

	// Temas
	const darkTheme = useMemo(() => {
		const base = Themes.getDarkTheme();
		const logos = themeConfig ? { ...base.logos, brandLogo: themeConfig.dark.brandLogo } : base.logos;

		return { ...base, logos, ...overrides?.dark };
	}, [themeConfig, overrides?.dark]);
	const lightTheme = useMemo(() => {
		const base = Themes.getLightTheme();
		const logos = themeConfig ? { ...base.logos, brandLogo: themeConfig.light.brandLogo } : base.logos;

		return { ...base, logos, ...overrides?.light };
	}, [themeConfig, overrides?.light]);

	// Puxamos o valor inicial da localstorage
	const [prefersDarkTheme, setPrefersDarkTheme] = useLocalStorage<boolean>('prefersDarkTheme', false);
	const [theme, setTheme] = useState<DSThemeContext>(prefersDarkTheme ? darkTheme : lightTheme);

	// Sempre que a preferência muda, atualiza o tema
	useEffect(() => {
		setTheme(prefersDarkTheme ? darkTheme : lightTheme);
	}, [prefersDarkTheme, darkTheme, lightTheme]);

	const currentTheme: ThemeVariant = prefersDarkTheme ? 'dark' : 'light';
	const toggleTheme = useCallback(() => setPrefersDarkTheme((current) => !current), [setPrefersDarkTheme]);

	const context = useMemo<IThemeContext>(() => {
		return { currentTheme, toggleTheme };
	}, [currentTheme, toggleTheme]);

	return (
		<>
			{/* Esse provider ajusta o tema dos componentes do ant design */}
			{/* Cor primária deve ser hardcoded pd o componente não aceita variáveis css (como as presentes no hook useTheme) */}
			<ConfigProvider
				locale={locale}
				theme={{
					token: {
						colorPrimary: '#006A6A', // Primary Light
						colorLink: '#006A6A', // Primary Light
					},
				}}
			>
				<ThemeContext.Provider value={context}>
					<DSThemeContextProvider theme={theme ?? Themes.getLightTheme()}>{children}</DSThemeContextProvider>
				</ThemeContext.Provider>
			</ConfigProvider>
		</>
	);
};
