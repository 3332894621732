import { Card, Flex, Typography, useTheme } from '@jcm/design-system';
import { memo } from 'react';

interface IDashboardCardProps {
	title?: string;
	compData?: string;
	subTitle?: string;

	addon?: React.ReactNode;
	children: React.ReactNode;
}
export const DashboardCard = memo<IDashboardCardProps>((props) => {
	const { colors, shapes } = useTheme();

	return (
		<Card
			style={{
				height: '100%',
				width: '100%',
				backgroundColor: colors.containerLowest,
				padding: shapes.sizes.large,
				borderRadius: shapes.sizes.medium,
			}}
		>
			<Flex vertical gap={shapes.sizes.large}>
				<Flex justify='space-between'>
					<Flex
						vertical
						gap={'1rem'}
						style={{
							width: '100%',
							paddingBottom: shapes.sizes.large,
							borderBottom: '1px solid ' + colors.outlineVariant,
						}}
					>
						{!!props.title && (
							<Typography.Title
								size='large'
								style={{
									fontWeight: 500,
									fontSize: '1.25rem',
								}}
							>
								{props.title}
							</Typography.Title>
						)}
						{!!props.compData && (
							<Typography.Title
								size='large'
								style={{
									fontWeight: 700,
									fontSize: '2rem',
								}}
							>
								{props.compData}
							</Typography.Title>
						)}
						{!!props.subTitle && (
							<Typography.Body
								size='large'
								style={{
									fontWeight: 500,
									fontSize: '1rem',
								}}
							>
								{props.subTitle}
							</Typography.Body>
						)}
					</Flex>
					{props.addon}
				</Flex>

				{props.children}
			</Flex>
		</Card>
	);
});
