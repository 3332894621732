import { DrawerBar, Flex, MobileLayout, Skeleton, Typography, useTheme } from '@jcm/design-system';
import { memo } from 'react';

import {
	NotificationsIconButton,
	SeletorParticipacao,
	SeloISO,
	ToggleThemeIconButton,
	ToggleVisibilityButton,
	UserAvatar,
} from 'components';
import { useParticipanteIdentityContext } from 'context';
import { useNavigateWithSearchParams } from 'hooks';
import { useNotificacoesQuery } from 'queries/participante';

import { ILayoutVariantProps } from '.';

interface IMobileProps extends ILayoutVariantProps {}
export const Mobile = memo<IMobileProps>(
	({ id, drawerBarItems, participacaoAtiva, children, queryFn, onParticipacaoChange }) => {
		const navigateWithSearchParams = useNavigateWithSearchParams();
		const { logos, shapes } = useTheme();

		const { idParticipacao } = useParticipanteIdentityContext();
		const { isPending } = queryFn(idParticipacao);

		const getContentOrSkeleton = (content: React.ReactNode) =>
			isPending ? <Skeleton.Input active style={{ width: '100%' }} /> : content;

		const header = (
			<Flex flex={1} justify='space-between' align='center'>
				<Flex vertical>
					<Typography.Body variant='primary' strong>
						{getContentOrSkeleton(<>Olá, {participacaoAtiva?.nome.split(' ')[0]}</>)}
					</Typography.Body>

					<Typography.Body size='small'>{participacaoAtiva?.status}</Typography.Body>
				</Flex>

				<Flex gap='0.25rem'>
					<ToggleVisibilityButton loading={isPending} />
					<NotificationsIconButton useQueryFn={useNotificacoesQuery} isMobile />
					<ToggleThemeIconButton />
				</Flex>
			</Flex>
		);

		return (
			<MobileLayout
				id={id}
				sider={
					<Flex gap={shapes.sizes.large} flex={1} vertical style={{ padding: shapes.sizes.medium }}>
						<UserAvatar
							nome={participacaoAtiva?.nome.split(' ')[0]}
							status={participacaoAtiva?.status}
							placement='bottom'
						/>

						<DrawerBar
							items={drawerBarItems}
							container='surface'
							onSelect={(info) => navigateWithSearchParams(info.key)}
							style={{ padding: 0, flexGrow: 1 }}
						/>

						<SeloISO />
					</Flex>
				}
				logo={logos.brandLogo}
				header={header}
			>
				<SeletorParticipacao
					idParticipacaoAtiva={participacaoAtiva?.participacaoId}
					onParticipacaoChange={onParticipacaoChange}
					queryFn={queryFn}
				/>

				{children}
			</MobileLayout>
		);
	},
);
