import { GetFn } from 'api';

import { BaseApi } from './BaseApi';

// Filtro
export type GetParticipantesArgs = {
	filtro: string;
	pagina: number;
	tamanho: number;
};

export type GetParticipantesReturnType = {
	empregador: string | null;
	nome: string;
	matricula: number | null;
	cpf: number | null;
	plano: string | null;
	participacaoId: number;
	tipoVinculoParticipacao: string | null;
	situacaoParticipacao: string | null;
};

export interface IAdminApi {
	getParticipantes: GetFn<readonly GetParticipantesReturnType[], GetParticipantesArgs>;
}

/**
 * Classe que contém os métodos relacionados à API do portal administrador.
 *
 * Por padrão, o nome dos métodos deve iniciar com o verbo HTTP que será chamado (get, post, put, etc.).
 */
export class AdminApi extends BaseApi implements IAdminApi {
	// Filtro Participante
	public getParticipantes: GetFn<readonly GetParticipantesReturnType[], GetParticipantesArgs> = async (params) => {
		return await this.getRequest<GetParticipantesReturnType[]>(`/api/admin/participantes`, params);
	};
}
