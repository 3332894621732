import { DrawerBarItem, FloatButton, Icons, Row, Skeleton, useTheme } from '@jcm/design-system';
import { Outlet } from 'react-router-dom';
import { useDocumentTitle } from 'usehooks-ts';

import { GetParticipacoesPlanosReturnType } from 'api';
import { Error, Loading, LoadingSkeleton, SeletorTipoParticipante } from 'components';
import { useParticipanteIdentityContext } from 'context';
import { useIsMobile, useNodeEnv } from 'hooks';
import { useParticipacoesPlanosQuery } from 'queries/participante';
import { RotasParticipante } from 'routes';

import { Desktop } from './Desktop';
import { Mobile } from './Mobile';

export interface ILayoutVariantProps {
	id?: string;
	children?: React.ReactNode;

	drawerBarItems: DrawerBarItem[];
	participacaoAtiva?: GetParticipacoesPlanosReturnType;
	queryFn: typeof useParticipacoesPlanosQuery;
	onParticipacaoChange: (value: number) => void;
}

export const LayoutParticipante: React.FC<{ id?: string }> = ({ id }) => {
	const { shapes, colors } = useTheme();
	const nodeEnv = useNodeEnv();

	const { changeIdParticipacao, idParticipacao, participacaoAtiva } = useParticipanteIdentityContext();
	const { isPending, isError, refetch } = useParticipacoesPlanosQuery(idParticipacao);

	// Nome da aba
	const primeiroNome = participacaoAtiva?.nome.split(' ')[0];
	useDocumentTitle(primeiroNome ? `${primeiroNome} - Portal do Participante` : 'Portal do Participante');

	const isMobile = useIsMobile();
	const LayoutComponent: React.FC<ILayoutVariantProps> = isMobile ? Mobile : Desktop;

	const drawerBarItems = [
		{
			label: 'Início',
			key: RotasParticipante.get('home'),
			icon: <Icons.Home />,
		},
		{
			label: 'Saldo',
			key: RotasParticipante.get('saldo'),
			icon: <Icons.Paid />,
		},
		{
			label: 'Contribuição',
			key: RotasParticipante.get('contribuicao'),
			icon: <Icons.PriceChange />,
		},
		{
			label: 'Rentabilidade',
			key: RotasParticipante.get('rentabilidade'),
			icon: <Icons.Savings />,
		},
		{
			label: 'Portabilidade',
			key: RotasParticipante.get('portabilidade'),
			icon: <Icons.CompareArrows />,
			disabled: true,
		},
		{
			label: 'Benefícios',
			key: RotasParticipante.get('beneficios'),
			icon: <Icons.Payments />,
		},
		{
			label: 'Simulação',
			key: RotasParticipante.get('simulacao'),
			icon: <Icons.BrowseActivity />,
		},
		{
			label: 'Requerimentos',
			key: RotasParticipante.get('requerimentos'),
			icon: <Icons.ContractEdit />,
		},
		{
			label: 'Empréstimo',
			key: RotasParticipante.get('emprestimo'),
			icon: <Icons.AccountBalance />,
			disabled: true,
		},
		{
			label: 'Dados Cadastrais',
			key: RotasParticipante.get('dadosCadastrais'),
			icon: <Icons.Person />,
		},
		{
			label: 'SAC',
			key: RotasParticipante.get('sac'),
			icon: <Icons.Help />,
			disabled: true,
		},

		// TODO - Remover
		{
			label: 'Alteração de custeio',
			key: '/participante/alteracao',
			icon: <Icons.Help />,
		},
	];

	return (
		<>
			<Loading loading={isPending} />

			<LayoutComponent
				id={id}
				drawerBarItems={drawerBarItems}
				queryFn={useParticipacoesPlanosQuery}
				participacaoAtiva={participacaoAtiva}
				onParticipacaoChange={changeIdParticipacao}
			>
				<div style={{ padding: '0.5rem 1rem', color: colors.onSurface }}>
					{nodeEnv !== 'production' && (
						<Row justify='end' style={{ marginBottom: shapes.sizes.large }}>
							{isPending ? (
								<Skeleton.Input active />
							) : (
								<SeletorTipoParticipante style={{ width: isMobile ? '100%' : 'auto' }} />
							)}
						</Row>
					)}

					{isPending ? <LoadingSkeleton /> : isError ? <Error refetch={refetch} /> : <Outlet />}

					<FloatButton.BackTop />
				</div>
			</LayoutComponent>
		</>
	);
};
