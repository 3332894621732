import { DrawerBar, Flex, MobileLayout, Skeleton, Typography, useTheme } from '@jcm/design-system';
import { memo } from 'react';

import {
	NotificationsIconButton,
	SeloISO,
	ToggleThemeIconButton,
	ToggleVisibilityButton,
	UserAvatar,
} from 'components';
import { useNavigateWithSearchParams } from 'hooks';
import { useNotificacoesQuery } from 'queries/participante';

import { ILayoutAdminVariantProps } from '.';

interface IMobileProps extends ILayoutAdminVariantProps {}
export const Mobile = memo<IMobileProps>(({ id, drawerBarItems, children }) => {
	// TODO - Implementar uma query pra trazer dados?
	const isPending = false;

	const navigateWithSearchParams = useNavigateWithSearchParams();
	const { logos, shapes } = useTheme();

	const getContentOrSkeleton = (content: React.ReactNode) =>
		isPending ? <Skeleton.Input active style={{ width: '100%' }} /> : content;

	const header = (
		<Flex flex={1} justify='space-between' align='center'>
			<Flex vertical>
				<Typography.Body variant='primary' strong>
					{getContentOrSkeleton(<>Olá, {'Administrador'}</>)}
				</Typography.Body>

				<Typography.Body size='small'>{'Status'}</Typography.Body>
			</Flex>

			<Flex gap='0.25rem'>
				<ToggleVisibilityButton loading={isPending} />
				<NotificationsIconButton useQueryFn={useNotificacoesQuery} isMobile />
				<ToggleThemeIconButton />
			</Flex>
		</Flex>
	);

	return (
		<MobileLayout
			id={id}
			sider={
				<Flex gap={shapes.sizes.large} flex={1} vertical style={{ padding: shapes.sizes.medium }}>
					<UserAvatar nome={'Administrador'} status={'Status'} placement='bottom' />

					<DrawerBar
						items={drawerBarItems}
						container='surface'
						onSelect={(info) => navigateWithSearchParams(info.key)}
						style={{ padding: 0, flexGrow: 1 }}
					/>

					<SeloISO />
				</Flex>
			}
			logo={logos.brandLogo}
			header={header}
		>
			{children}
		</MobileLayout>
	);
});
