import { Select } from '@jcm/design-system';
import { memo } from 'react';

import { useParticipanteIdentityContext } from 'context';
import { TipoParticipanteEnum } from 'lib';

interface ISeletorTipoParticipanteProps {
	id?: string;
	className?: string;
	style?: React.CSSProperties;
}
/**
 * Seletor que controla `tipoParticipante`, acessando `IdentityContext`
 */
export const SeletorTipoParticipante = memo<ISeletorTipoParticipanteProps>(({ style, ...props }) => {
	const { tipoParticipante, changeTipoParticipante } = useParticipanteIdentityContext();

	return (
		<Select
			value={tipoParticipante}
			onChange={(value: TipoParticipanteEnum) => changeTipoParticipante(value)}
			variant='filled'
			options={[
				{ label: TipoParticipanteEnum.AtivoComEmprestimo, value: TipoParticipanteEnum.AtivoComEmprestimo },
				{ label: TipoParticipanteEnum.AtivoSemEmprestimo, value: TipoParticipanteEnum.AtivoSemEmprestimo },

				{ label: TipoParticipanteEnum.BPDComEmprestimo, value: TipoParticipanteEnum.BPDComEmprestimo },
				{ label: TipoParticipanteEnum.BPDSemEmprestimo, value: TipoParticipanteEnum.BPDSemEmprestimo },

				{
					label: TipoParticipanteEnum.RendaFinanceiraProgressivaComEmprestimo,
					value: TipoParticipanteEnum.RendaFinanceiraProgressivaComEmprestimo,
				},
				{
					label: TipoParticipanteEnum.RendaFinanceiraProgressivaSemEmprestimo,
					value: TipoParticipanteEnum.RendaFinanceiraProgressivaSemEmprestimo,
				},

				{ label: TipoParticipanteEnum.VitalicioComEmprestimo, value: TipoParticipanteEnum.VitalicioComEmprestimo },
				{ label: TipoParticipanteEnum.VitalicioSemEmprestimo, value: TipoParticipanteEnum.VitalicioSemEmprestimo },

				{ label: TipoParticipanteEnum.PensionistaRegressivo, value: TipoParticipanteEnum.PensionistaRegressivo },
				{ label: TipoParticipanteEnum.PensionistaProgressivo, value: TipoParticipanteEnum.PensionistaProgressivo },

				{ label: TipoParticipanteEnum.DesligadoComEmprestimo, value: TipoParticipanteEnum.DesligadoComEmprestimo },
				{ label: TipoParticipanteEnum.DesligadoSemEmprestimo, value: TipoParticipanteEnum.DesligadoSemEmprestimo },

				{
					label: TipoParticipanteEnum.AutopatrocinadoComEmprestimo,
					value: TipoParticipanteEnum.AutopatrocinadoComEmprestimo,
				},
				{
					label: TipoParticipanteEnum.AutopatrocinadoSemEmprestimo,
					value: TipoParticipanteEnum.AutopatrocinadoSemEmprestimo,
				},
			]}
			style={{ minWidth: '200px', ...style }}
			{...props}
		/>
	);
});
