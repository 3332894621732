import { memo } from 'react';

import { useVisibilityContext } from 'context';
import { NumberFormatter } from 'lib';

const Placeholder = memo(() => <span style={{ userSelect: 'none' }}>.....</span>);

const CurrencyPlaceholder = memo(() => {
	return (
		<span style={{ whiteSpace: 'nowrap' }}>
			R$ <Placeholder />
		</span>
	);
});

const PercentPlaceholder = memo(() => {
	return (
		<>
			<Placeholder />%
		</>
	);
});

interface IDataPlaceholder {
	type?: 'default' | 'currency' | 'percent';
	children?: React.ReactNode;
}
export const DataPlaceholder = memo<IDataPlaceholder>(({ type = 'default', children }) => {
	const { isVisible } = useVisibilityContext();
	let placeholder: JSX.Element;

	switch (type) {
		case 'default':
			placeholder = <Placeholder />;
			break;
		case 'currency':
			placeholder = <CurrencyPlaceholder />;
			break;
		case 'percent':
			placeholder = <PercentPlaceholder />;
	}

	return <>{isVisible ? children : placeholder}</>;
});

export const getValueOrDataPlaceholder = (value?: React.ReactNode) => {
	return <DataPlaceholder>{value}</DataPlaceholder>;
};

export const getCurrencyOrDataPlaceholder = (value?: React.ReactNode) => {
	if (typeof value !== 'number') return value;

	return <DataPlaceholder type='currency'>{NumberFormatter.format(value, 'BRL')}</DataPlaceholder>;
};

export const getValueOrPercentPlaceholder = (value?: React.ReactNode) => {
	if (typeof value !== 'number') return value;

	return <DataPlaceholder type='percent'>{NumberFormatter.format(value, '%')}</DataPlaceholder>;
};
