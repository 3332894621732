import { Typography } from '@jcm/design-system';
import { memo } from 'react';

interface IFormErrorMessageProps {
	value?: string;
}
export const FormErrorMessage = memo<IFormErrorMessageProps>(({ value }) => {
	return (
		<Typography.Body size='small' variant='error'>
			{value}
		</Typography.Body>
	);
});
