import { Button, Col, Row, Typography } from '@jcm/design-system';
import { memo } from 'react';

import avestruz from 'assets/images/avestruz.svg';
import styles from './index.module.scss';

export type ErrorProps = { refetch: () => void; id?: string; loading?: boolean };
export const Error = memo<ErrorProps>(({ refetch, id, loading }) => {
	return (
		<Row
			className={styles.row}
			style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%' }}
			id={id}
		>
			<Col
				span={24}
				className={styles.col}
				style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '1rem' }}
			>
				<Row align={'middle'}>
					<img src={avestruz} alt='' id='img-avestruz' style={{ height: 250 }} />
				</Row>
				<Typography.Title variant='error' size='medium' style={{ textAlign: 'center' }}>
					Algo deu errado!
				</Typography.Title>

				<Button
					variant='default'
					type='filled-tonal'
					onClick={refetch}
					id='btn-tentar-novamente'
					style={{ textAlign: 'center' }}
					loading={loading}
				>
					Tentar novamente
				</Button>
			</Col>
		</Row>
	);
});
