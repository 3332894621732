import { Col, Flex, Icons, IThemeContextColors, Row, Typography, useTheme } from '@jcm/design-system';
import { nanoid } from 'nanoid';
import { memo, useState } from 'react';

import { Area, IAreaProps } from './components/Area';
import { DashboardCard } from './cards/DashboardCard';
import {
	Arrecadacao,
	ArrecadacaoPlano,
	ArrecadacaoTotal,
	ComparativoRentabilidade,
	ConcessoesTotais,
	EmprestimosAtivos,
	ParticipantesFundoReversao,
	ParticipantesPorPlano,
	PatrimonioConta,
	PatrimonioTotal,
	QntEmprestimos,
	RentabilidadePerfil,
	RentabilidadeTotal,
	SaldoCarteira,
	SituacoesPorParticipante,
	TiposConcessoes,
	TiposDeDado,
	TotalPago,
	TotalParticipantes,
	TotalPatrocinadora,
	TotalPlanos,
	ValorAtraso,
} from './graficos';
import { SeletorCaixas } from './components/SeletorCaixas';

/**
 * Função que retona uma relação `tipo de dado => cor`
 */
export const getCoresPorTipoDeDado = (colors: IThemeContextColors): Record<TiposDeDado, string> => {
	return {
		CDI: colors.error,
		IPCA: colors.magenta,
		Ibovespa: colors.primary,
		Perfil1: '#2D9CDB',
		Perfil2: colors.tertiary,
		Perfil3: colors.roxo,
		Perfil4: colors.roxoContainer,
		Perfil5: colors.verde,
		Perfil6: colors.amarelo,
		Poupança: '#758418',
	};
};

export const Dashboard = memo(() => {
	const { shapes } = useTheme();
	const [tiposSelecionados, setTiposSelecionados] = useState<TiposDeDado[]>([]);
	// TODO - Implementar esse filtro
	const filtro: string[] = [];
	const todosTipos: TiposDeDado[] = [
		'Perfil1',
		'Poupança',
		'CDI',
		'IPCA',
		'Ibovespa',
		'Perfil2',
		'Perfil3',
		'Perfil4',
		'Perfil5',
		'Perfil6',
	];
	const todasAsAreas: IAreaProps[] = [
		{
			title: 'Geral',
			children: (
				<Row gutter={[16, 16]}>
					<Col xs={24} md={12} xl={8}>
						<Flex vertical gap={shapes.sizes.large}>
							<DashboardCard>
								<TotalParticipantes />
							</DashboardCard>
							<DashboardCard>
								<TotalPlanos />
							</DashboardCard>
							<DashboardCard>
								<TotalPatrocinadora />
							</DashboardCard>
						</Flex>
					</Col>

					<Col xs={24} md={12} xl={8}>
						<DashboardCard title='Participantes por plano'>
							<ParticipantesPorPlano />
						</DashboardCard>
					</Col>

					<Col xs={24} md={12} xl={8}>
						<DashboardCard title='Situação dos participantes'>
							<SituacoesPorParticipante />
						</DashboardCard>
					</Col>
				</Row>
			),
		},

		{
			title: 'Arrecadação',
			children: (
				<Row gutter={[16, 16]}>
					<Col xs={24}>
						<DashboardCard title='Arrecadação por plano'>
							<ArrecadacaoPlano />
						</DashboardCard>
					</Col>

					<Col xs={24} md={12} xl={8}>
						<DashboardCard title='Arrecadação'>
							<Arrecadacao />
						</DashboardCard>
					</Col>
					<Col xs={24} md={12} xl={8}>
						<Flex justify='stretch'>
							<DashboardCard>
								<ArrecadacaoTotal />
							</DashboardCard>
						</Flex>
					</Col>
				</Row>
			),
		},

		{
			title: 'Concessões',
			children: (
				<Row gutter={[16, 16]}>
					<Col xs={24} md={12} xl={8}>
						<DashboardCard>
							<TotalPago />
						</DashboardCard>
					</Col>
					<Col xs={24} md={12} xl={8}>
						<Flex justify='stretch'>
							<DashboardCard>
								<ConcessoesTotais />
							</DashboardCard>
						</Flex>
					</Col>
					<Col xs={24}>
						<DashboardCard title='Tipos de concessões'>
							<TiposConcessoes />
						</DashboardCard>
					</Col>
				</Row>
			),
		},

		{
			title: 'Empréstimo',
			children: (
				<Row gutter={[16, 16]}>
					<Col xs={24} md={12} xl={8}>
						<DashboardCard>
							<SaldoCarteira />
						</DashboardCard>
					</Col>
					<Col xs={24} md={12} xl={8}>
						<DashboardCard>
							<QntEmprestimos />
						</DashboardCard>
					</Col>
					<Col xs={24} md={12} xl={8}>
						<DashboardCard>
							<ValorAtraso />
						</DashboardCard>
					</Col>

					<Col xs={24}>
						<DashboardCard
							title='Empréstimos ativos'
							compData='R$ 246.856.542,85'
							subTitle='Valor total em empréstimos'
						>
							<EmprestimosAtivos />
						</DashboardCard>
					</Col>
				</Row>
			),
		},

		{
			title: 'Rentabilidade',
			children: (
				<Row gutter={[16, 16]}>
					<Col xs={24} md={12} xl={8}>
						<DashboardCard>
							<RentabilidadeTotal />
						</DashboardCard>
					</Col>
					<Col xs={24}>
						<DashboardCard
							title='Rentabilidade por perfil'
							addon={
								<Typography.Body
									style={{
										fontSize: 20,
									}}
								>
									<Icons.Help />
								</Typography.Body>
							}
						>
							<RentabilidadePerfil />
						</DashboardCard>
					</Col>
					<Col xs={24}>
						<DashboardCard
							title='Comparativo de rentabilidade'
							addon={
								<Typography.Body
									style={{
										fontSize: 20,
									}}
								>
									<Icons.Help />
								</Typography.Body>
							}
						>
							<ComparativoRentabilidade tiposDeDados={tiposSelecionados} />
							<SeletorCaixas
								value={tiposSelecionados}
								options={todosTipos}
								onChange={(checkedValue) => setTiposSelecionados(checkedValue as TiposDeDado[])}
							/>
						</DashboardCard>
					</Col>
				</Row>
			),
		},

		{
			title: 'Patrimônio',
			children: (
				<Row gutter={[16, 16]}>
					<Col xs={24} md={12} xl={8}>
						<DashboardCard title='Participantes por Fundo de Reversão'>
							<ParticipantesFundoReversao />
						</DashboardCard>
					</Col>

					<Col xs={24} md={12} xl={16}>
						<DashboardCard title='Patrimônio por conta'>
							<PatrimonioConta />
						</DashboardCard>
					</Col>

					<Col xs={24} md={12} xl={8}>
						<DashboardCard>
							<PatrimonioTotal />
						</DashboardCard>
					</Col>
				</Row>
			),
		},
	];

	return (
		<Flex gap='2rem' vertical>
			{todasAsAreas
				.filter((props) => {
					if (filtro.length === 0) return true;

					return filtro.includes(props.title);
				})
				.map((props) => (
					<Area key={nanoid()} title={props.title}>
						{props.children}
					</Area>
				))}
		</Flex>
	);
});
