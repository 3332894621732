import { Col, Icons, Row } from '@jcm/design-system';
import { memo, useState } from 'react';

import { PaginaParticipante } from 'components';

import { AlertaMinContribuicao } from './AlertaMinContribuicao';
import { AlteracaoCusteioCard } from './cards';

// TODO - Mover para a tela de contribuição quando pronto
export const AlteracaoDeCusteio = memo(() => {
	const [totalPorcentagem, setTotalPorcentagem] = useState(false);

	const handleTotalPorcentagemChange = (isTotal: boolean) => {
		setTotalPorcentagem(isTotal);
	};

	return (
		<PaginaParticipante id='id-da-pagina' icon={<Icons.PriceChange variant='outlined' />} title='Alteração de Custeio'>
			<Row gutter={[16, 16]}>
				<Col xs={24} xl={24} xxl={24}>
					{!totalPorcentagem && <AlertaMinContribuicao />}
				</Col>

				<Col xs={24} xl={8} xxl={8}>
					<AlteracaoCusteioCard
						onTotalPorcentagemChange={handleTotalPorcentagemChange}
						configConta={{
							contribuicaoPlano: 'Contribuição extraordinária',
							contribuicaoPlanoCodigo: '0501394302',
							contribuicaoTotalNoMes: 500,
							percentualAtual: 0.3,
							valorAtual: 300,
							contrapartidaAtual: 200,
							configsContribucoes: [],
						}}
						salarioParticipacao={2500}
						porcentagemContrapartida={0.4}
					/>
				</Col>
				<Col xs={24} xl={8} xxl={8}>
					<AlteracaoCusteioCard
						configConta={{
							contribuicaoPlano: 'Contribuição extraordinária',
							contribuicaoPlanoCodigo: '0501394302',
							contribuicaoTotalNoMes: 500,
							percentualAtual: 0.3,
							valorAtual: 300,
							contrapartidaAtual: 200,
							configsContribucoes: [],
						}}
						salarioParticipacao={2500}
						porcentagemContrapartida={0.4}
					/>
				</Col>
				<Col xs={24} xl={8} xxl={8}>
					<AlteracaoCusteioCard
						configConta={{
							contribuicaoPlano: 'Contribuição extraordinária',
							contribuicaoPlanoCodigo: '0501394302',
							contribuicaoTotalNoMes: 500,
							percentualAtual: 0.3,
							valorAtual: 300,
							contrapartidaAtual: 200,
							configsContribucoes: [],
						}}
						salarioParticipacao={2500}
						porcentagemContrapartida={0.4}
					/>
				</Col>
			</Row>
		</PaginaParticipante>
	);
});
