import { Select, Typography } from '@jcm/design-system';
import { memo } from 'react';

import { useParticipanteIdentityContext } from 'context';

import { ISeletorParticipacaoProps } from '.';

interface ISeletorParticipacaoDesktopProps extends ISeletorParticipacaoProps {}
export const SeletorParticipacaoDesktop = memo<ISeletorParticipacaoDesktopProps>(
	({ idParticipacaoAtiva, queryFn, onParticipacaoChange }) => {
		const { idParticipacao } = useParticipanteIdentityContext();

		const { data, isPending } = queryFn(idParticipacao);

		return (
			<Select
				id='plano-select'
				loading={isPending}
				variant='filled'
				options={data?.map((p) => ({ label: p.plano, value: p.participacaoId }))}
				onChange={(value: number) => onParticipacaoChange(value)}
				value={idParticipacaoAtiva}
				style={{ minWidth: '200px' }}
				label={
					<Typography.Title size='medium' variant='primary'>
						Plano
					</Typography.Title>
				}
			/>
		);
	},
);
