import { Col, Row, useTheme } from '@jcm/design-system';
import { ColProps, RowProps } from 'antd';
import { Suspense, lazy, memo, useMemo } from 'react';

import { LoadingSkeleton, PaginaParticipante } from 'components';
import { useParticipanteIdentityContext } from 'context';
import { TipoParticipanteEnum } from 'lib';

const Emprestimo = lazy(() => import('./emprestimo').then((module) => ({ default: module.Emprestimo })));
const MeuBeneficio = lazy(() => import('./meuBeneficio').then((module) => ({ default: module.MeuBeneficio })));
const MinhaRentabilidade = lazy(() =>
	import('./minhaRentabilidade').then((module) => ({ default: module.MinhaRentabilidade })),
);
const MinhasContribuicoes = lazy(() =>
	import('./minhasContribuicoes').then((module) => ({ default: module.MinhasContribuicoes })),
);
const Saldo = lazy(() => import('./saldo').then((module) => ({ default: module.Saldo })));
const Simulacao = lazy(() => import('./simulacao').then((module) => ({ default: module.Simulacao })));

type ColunaDashboard = { props?: ColProps; componente: React.ReactNode };
type LinhaDashboard = { props?: RowProps; itens: ColunaDashboard[] };
type LayoutDashboard = LinhaDashboard[];

const getLayout = (tipo: TipoParticipanteEnum | null): LayoutDashboard => {
	const layoutAtivoComEmprestimo: LayoutDashboard = [
		{
			itens: [
				// Linha 01
				{ props: { xs: 24, xl: 7, xxl: 6 }, componente: <Saldo /> },
				{ props: { xs: 24, xl: 7, xxl: 6 }, componente: <MinhasContribuicoes /> },
				{ props: { xs: 24, xl: 10, xxl: 12 }, componente: <MinhaRentabilidade /> },

				// Linha 02
				{ props: { span: 24 }, componente: <Simulacao /> },

				// Linha 03
				{ props: { span: 24 }, componente: <Emprestimo /> },
			],
		},
	];

	const layoutAtivoSemEmprestimo: LayoutDashboard = [
		{
			itens: [
				// Linha 01
				{ props: { xs: 24, xl: 7, xxl: 6 }, componente: <Saldo /> },
				{ props: { xs: 24, xl: 7, xxl: 6 }, componente: <MinhasContribuicoes /> },
				{ props: { xs: 24, xl: 10, xxl: 12 }, componente: <MinhaRentabilidade /> },

				// Linha 02
				{ props: { span: 24 }, componente: <Simulacao /> },
			],
		},
	];

	const layoutBPDComEmprestimo: LayoutDashboard = [
		{
			itens: [
				// Linha 01
				{ props: { xs: 24, xl: 12, xxl: 10 }, componente: <Saldo isHorizontal /> },
				{ props: { xs: 24, xl: 12, xxl: 14 }, componente: <MinhaRentabilidade isHorizontal /> },

				// Linha 02
				{ props: { span: 24 }, componente: <Emprestimo /> },
			],
		},
	];

	const layoutBPDSemEmprestimo: LayoutDashboard = [
		{
			itens: [
				// Linha 01
				{ props: { xs: 24, xl: 12, xxl: 10 }, componente: <Saldo isHorizontal /> },
				{ props: { xs: 24, xl: 12, xxl: 14 }, componente: <MinhaRentabilidade isHorizontal /> },
			],
		},
	];

	const layoutRendaFinanceiraProgressivaComEmprestimo: LayoutDashboard = [
		{
			itens: [
				// Linha 01
				{ props: { xs: 24, xl: 10, xxl: 9 }, componente: <Saldo /> },
				{ props: { xs: 24, xl: 14, xxl: 15 }, componente: <MeuBeneficio /> },

				// Linha 02
				{ props: { xs: 24, xl: 24, xxl: 24 }, componente: <MinhaRentabilidade isHorizontal /> },

				// Linha 03
				{ props: { xs: 24, xl: 24, xxl: 24 }, componente: <Emprestimo /> },
			],
		},
	];

	const layoutRendaFinanceiraProgressivaSemEmprestimo: LayoutDashboard = [
		{
			itens: [
				// Linha 01
				{ props: { xs: 24, xl: 10, xxl: 9 }, componente: <Saldo /> },
				{ props: { xs: 24, xl: 14, xxl: 15 }, componente: <MeuBeneficio /> },

				// Linha 02
				{ props: { xs: 24, xl: 24, xxl: 24 }, componente: <MinhaRentabilidade isHorizontal /> },
			],
		},
	];

	const layoutVitalicioSemEmprestimo: LayoutDashboard = [
		{
			itens: [{ props: { span: 24 }, componente: <MeuBeneficio isHorizontal={true} /> }],
		},
	];

	const layoutVitalicioComEmprestimo: LayoutDashboard = [
		{
			itens: [
				// Linha 01
				{ props: { span: 24 }, componente: <MeuBeneficio isHorizontal /> },

				// Linha 02
				{ props: { span: 24 }, componente: <Emprestimo /> },
			],
		},
	];

	const layoutPensionistaRegressivo: LayoutDashboard = [
		{
			itens: [
				// Linha 01
				{ props: { span: 24 }, componente: <MeuBeneficio isHorizontal /> },

				// Linha 02
				{ props: { span: 24 }, componente: <Emprestimo /> },
			],
		},
	];

	const layoutPensionistaProgressivo: LayoutDashboard = [
		{
			itens: [
				// Linha 01
				{ props: { span: 24 }, componente: <MeuBeneficio isHorizontal /> },

				// Linha 02
				{ props: { span: 24 }, componente: <Emprestimo /> },
			],
		},
	];

	const layoutDesligadoComEmprestimo: LayoutDashboard = [
		{
			itens: [
				// Linha 01
				{ props: { span: 24 }, componente: <MeuBeneficio isHorizontal /> },

				// Linha 02
				{ props: { span: 24 }, componente: <Emprestimo /> },
			],
		},
	];

	const layoutDesligadoSemEmprestimo: LayoutDashboard = [
		{
			itens: [
				// Linha 01
				{ props: { span: 24 }, componente: <MeuBeneficio isHorizontal /> },
			],
		},
	];

	const layoutAutopatrocinadoComEmprestimo: LayoutDashboard = [
		{
			itens: [
				// Linha 01
				{ props: { xs: 24, xl: 7, xxl: 6 }, componente: <Saldo /> },
				{ props: { xs: 24, xl: 7, xxl: 6 }, componente: <MinhasContribuicoes /> },
				{ props: { xs: 24, xl: 10, xxl: 12 }, componente: <MinhaRentabilidade /> },

				// Linha 02
				{ props: { span: 24 }, componente: <Simulacao /> },

				// Linha 03
				{ props: { span: 24 }, componente: <Emprestimo /> },
			],
		},
	];

	const layoutAutopatrocinadoSemEmprestimo: LayoutDashboard = [
		{
			itens: [
				// Linha 01
				{ props: { xs: 24, xl: 7, xxl: 6 }, componente: <Saldo /> },
				{ props: { xs: 24, xl: 7, xxl: 6 }, componente: <MinhasContribuicoes /> },
				{ props: { xs: 24, xl: 10, xxl: 12 }, componente: <MinhaRentabilidade /> },

				// Linha 02
				{ props: { span: 24 }, componente: <Simulacao /> },
			],
		},
	];

	switch (tipo) {
		case TipoParticipanteEnum.AtivoComEmprestimo:
			return layoutAtivoComEmprestimo;
		case TipoParticipanteEnum.AtivoSemEmprestimo:
			return layoutAtivoSemEmprestimo;

		case TipoParticipanteEnum.BPDComEmprestimo:
			return layoutBPDComEmprestimo;
		case TipoParticipanteEnum.BPDSemEmprestimo:
			return layoutBPDSemEmprestimo;

		case TipoParticipanteEnum.RendaFinanceiraProgressivaComEmprestimo:
			return layoutRendaFinanceiraProgressivaComEmprestimo;
		case TipoParticipanteEnum.RendaFinanceiraProgressivaSemEmprestimo:
			return layoutRendaFinanceiraProgressivaSemEmprestimo;

		case TipoParticipanteEnum.VitalicioSemEmprestimo:
			return layoutVitalicioSemEmprestimo;
		case TipoParticipanteEnum.VitalicioComEmprestimo:
			return layoutVitalicioComEmprestimo;

		case TipoParticipanteEnum.PensionistaRegressivo:
			return layoutPensionistaRegressivo;
		case TipoParticipanteEnum.PensionistaProgressivo:
			return layoutPensionistaProgressivo;

		case TipoParticipanteEnum.DesligadoComEmprestimo:
			return layoutDesligadoComEmprestimo;
		case TipoParticipanteEnum.DesligadoSemEmprestimo:
			return layoutDesligadoSemEmprestimo;

		case TipoParticipanteEnum.AutopatrocinadoComEmprestimo:
			return layoutAutopatrocinadoComEmprestimo;
		case TipoParticipanteEnum.AutopatrocinadoSemEmprestimo:
			return layoutAutopatrocinadoSemEmprestimo;

		default:
			return [];
	}
};

export const PaginaInicial = memo(() => {
	const { tipoParticipante } = useParticipanteIdentityContext();
	const { shapes } = useTheme();

	const layout = useMemo<LayoutDashboard>(() => getLayout(tipoParticipante), [tipoParticipante]);

	return (
		<PaginaParticipante id='pagina-inicial'>
			{layout.map((linha, linhaIndex) => {
				return (
					<Row key={linhaIndex} gutter={[16, 8]} {...linha.props}>
						{linha.itens.map((coluna, colunaIndex) => {
							return (
								<Col style={{ margin: `${shapes.sizes.extraSmall} 0` }} {...coluna.props} key={colunaIndex}>
									<Suspense fallback={<LoadingSkeleton style={{ maxWidth: '100%', width: '100%' }} />}>
										{coluna.componente}
									</Suspense>
								</Col>
							);
						})}
					</Row>
				);
			})}
		</PaginaParticipante>
	);
});
