import { DesktopLayout, Flex, NavigationBar, Skeleton, useTheme } from '@jcm/design-system';
import { memo } from 'react';

import { SiderMenu } from 'components';
import { NotificationsIconButton, SearchIconButton, ToggleThemeIconButton } from 'components/header';
import { useThemeContext } from 'context';
import { useNavigateWithSearchParams } from 'hooks';
import { useNotificacoesQuery } from 'queries/participante';

import { ILayoutAdminVariantProps } from '.';
import styles from './index.module.scss';

interface IDesktopProps extends ILayoutAdminVariantProps {}
export const Desktop = memo<IDesktopProps>(({ id, drawerBarItems, children }) => {
	// TODO - Implementar uma query pra trazer dados?
	const isPending = false;
	const isError = false;

	const { shapes } = useTheme();
	const { currentTheme } = useThemeContext();
	const navigateWithSearchParams = useNavigateWithSearchParams();

	const getContentOrSkeleton = (content: React.ReactNode) =>
		isPending ? (
			<div style={{ width: '100%' }}>
				<Skeleton.Input active style={{ width: '100%' }} />
			</div>
		) : (
			content
		);

	const headerContent = getContentOrSkeleton(
		!isError && (
			<Flex vertical gap={shapes.sizes.extraSmall}>
				Conteúdo do header
			</Flex>
		),
	);

	const headerIcons = (
		<>
			<SearchIconButton />
			<NotificationsIconButton useQueryFn={useNotificacoesQuery} />
			<ToggleThemeIconButton />
		</>
	);

	return (
		<DesktopLayout
			id={id}
			className={currentTheme === 'light' ? styles.desktopLayoutLight : styles.desktopLayoutDark}
			sider={
				<SiderMenu
					items={drawerBarItems}
					onSelect={(info) => navigateWithSearchParams(info.key)}
					// TODO - Trazer o nome e status do admin
					nome={'Administrador'}
					status={'Status'}
				/>
			}
			header={<NavigationBar container='surface' content={headerContent} icons={headerIcons} />}
		>
			{children}
		</DesktopLayout>
	);
});
