import { Button, Divider, Flex, Icons, SearchBar, Surface, Tag, Typography, useTheme } from '@jcm/design-system';
import { memo } from 'react';

type HeaderProps = {
	limparInput: () => void;
	onInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
	valorInput: string;
};

export const Header = memo<HeaderProps>(({ limparInput, onInputChange, valorInput }) => {
	const { colors, shapes } = useTheme();

	return (
		<Surface variant='default' style={{ padding: '1rem 0' }}>
			<Flex vertical gap='0.5rem'>
				<Flex>
					<Typography.Title size='large' variant='primary' style={{ fontWeight: 600 }}>
						Filtro
					</Typography.Title>
				</Flex>
				<Divider style={{ margin: 0 }} />
				<Flex gap={'0.5rem'} align='center'>
					<SearchBar
						variant='filled'
						placeholder='Pesquisar'
						style={{ flex: 1, height: 35 }}
						onChange={(e) => onInputChange(e)}
						value={valorInput}
					/>
					<Flex
						justify='center'
						align='center'
						style={{
							backgroundColor: colors.surfaceVariant,
							borderRadius: '0.5rem',
							padding: '0.25rem 0.125rem',
							width: 32,
							height: 32,
						}}
					>
						<Typography.Title size='large' variant='tertiary'>
							<Icons.Person variant='outlined' />
						</Typography.Title>
					</Flex>
				</Flex>
				<Flex align='center' gap={'0.75rem'}>
					<Flex
						style={{
							backgroundColor: colors.inverseOnSurface,
							borderRadius: shapes.sizes.small,
							flex: 1,
							minHeight: 38,
							padding: shapes.sizes.small,
						}}
					>
						{valorInput.length > 0 ? (
							<Tag
								closable
								onClose={(e) => {
									e.preventDefault();
									limparInput();
								}}
								variant='laranja'
								style={{ borderRadius: shapes.sizes.small, width: 'fit-content' }}
							>
								{valorInput}
							</Tag>
						) : (
							<div></div>
						)}
					</Flex>
					<Button
						children={
							<Typography.Body size='medium' variant='primary' style={{ fontWeight: 500 }}>
								Limpar Filtro
							</Typography.Body>
						}
						onClick={() => limparInput()}
						style={{ width: 125 }}
						type='text'
						variant='default'
					/>
				</Flex>
			</Flex>
		</Surface>
	);
});
