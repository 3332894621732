import { Grid } from 'antd';
import axios, { CreateAxiosDefaults } from 'axios';
import { Dispatch, useEffect, useMemo, useReducer, useState } from 'react';
import { NavigateOptions, useNavigate, useSearchParams } from 'react-router-dom';

import { useParticipanteIdentityContext } from 'context';
import {
	TipoParticipanteEnum,
	ValidationFields,
	ValidationResult,
	Validator,
	checkObjectForProperty,
	checkValueForType,
} from 'lib';

export type ApiConfig = {
	url: string;
	clienteId: number | string;
};

export type KeycloakConfig = {
	url: string;
	devUrl: string;
	realm: string;
	participanteClientId: string;
	adminClientId: string;
};

export type ThemeVariantConfig = {
	brandLogo: string;
};

export type ThemeConfig = {
	light: ThemeVariantConfig;
	dark: ThemeVariantConfig;
};

export type GlobalConfig = {
	themeConfig?: ThemeConfig;
	keycloakConfig: KeycloakConfig;
	apiConfig: ApiConfig;
};

const validateGlobalConfig = (config: Record<string, object>) => {
	// Função que checa a existência de uma lista de propriedades de um objeto
	const validateConfig = (config: object, properties: string[]) => {
		return properties.every((property) => checkObjectForProperty(config, property));
	};

	// Propriedades esperadas dentro do arquivo de config
	const expectedProperties = [
		{ name: 'keycloakConfig', properties: ['url', 'devUrl', 'realm', 'participanteClientId', 'adminClientId'] },
		{ name: 'apiConfig', properties: ['url', 'clienteId'] },
	];

	expectedProperties.forEach(({ name, properties }) => {
		// Checa se as props existem
		if (!checkObjectForProperty(config, name)) {
			throw new Error(`[useGlobalConfigFile] ${name} ausente no arquivo de configuração!`);
		}

		const obj = config[name];

		// Checa se são objetos
		if (!checkValueForType(obj, 'object')) {
			throw new Error(`[useGlobalConfigFile] ${name} não é um objeto!`);
		}

		// Checa se as props existem dentro do objeto
		if (!validateConfig(obj, properties)) {
			throw new Error(`[useGlobalConfigFile] Configuração ${name} com formato inválido!`);
		}
	});

	return true;
};

export const useGlobalConfigFile = () => {
	const globalConfig = useMemo<GlobalConfig>(() => {
		// Variável definida em '/public/config.js'
		// @ts-ignore
		if (!validateGlobalConfig(publicAppConfig)) throw new Error('Arquivo de configuração inválido!');

		// @ts-ignore
		return publicAppConfig;
	}, []);

	return globalConfig;
};

export const useApi = <T>(options: CreateAxiosDefaults<T>) => {
	return axios.create(options);
};

/**
 * Retorna uma função de navegação que ignora outros event listeners no componente
 */
export const useNavigateOnClick = () => {
	const navigate = useNavigate();

	const navigateOnClick = (e: React.MouseEvent, path: string) => {
		e.stopPropagation();

		return navigate(path);
	};

	return navigateOnClick;
};

/**
 * Retorna uma função de navegação, que mantém todos os searchParams da URL
 */
export const useNavigateWithSearchParams = () => {
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();

	return (to: string, options?: NavigateOptions) => {
		const params: string[] = [];
		searchParams.forEach((value, key) => params.push(`${key}=${value}`));
		const path = `${to}?${params.join('&')}`;

		return navigate(path, options);
	};
};

export type ObjectReducerActionType<T = unknown> =
	| { type: 'update'; value: Partial<T> }
	| { type: 'validate' | 'save' | 'reset' };
export type ObjectReducerDispatch<T = unknown> = Dispatch<ObjectReducerActionType<T>>;

export type ObjectReducerOptions<T = unknown> = {
	onValidate?: (data?: T) => void;
	onSave?: (data?: T) => void;
	onReset?: () => void;
};
export type ObjectReducerReturnType<T = unknown> = [Partial<T>, ObjectReducerDispatch<T>];

/**
 * Hook feito pra abstrair a lógica de manuseio de dados de um formulário,
 * com métodos para atualizar os dados, resetar aos valores iniciais, validar o formulário e salvar.
 *
 * @param data Objeto que contém os dados do formulário. **Não suporta objetos aninhados por enquanto!**
 * @param options Opções e callbacks, como `onSave` e `onValidate`
 * @returns Mesmo retorno de `useReducer`
 */
export const useFormReducerFromObject = <T>(initialData?: T, options?: ObjectReducerOptions<T>) => {
	const reducer = (state: T | undefined, action: ObjectReducerActionType<T>) => {
		switch (action.type) {
			case 'update':
				return { ...state, ...action.value } as T;

			case 'validate':
				options?.onValidate?.(state);
				return state;

			case 'reset':
				options?.onReset?.();
				return initialData;

			case 'save':
				options?.onSave?.(state);
				return state;
		}
	};

	const [state, dispatch] = useReducer(reducer, initialData);

	// Qualquer alteração nos dados iniciais gera um evento "reset"
	// Dessa forma, um `refetch` na query de origem dos dados vai gerar um update nan interface
	useEffect(() => {
		if (!initialData) return;
		dispatch({ type: 'reset' });
	}, [initialData]);

	return [state, dispatch] as ObjectReducerReturnType<T>;
};

export type ArrayReducerActionType<T = unknown> =
	| { type: 'add'; value: T }
	| { type: 'remove'; findFn: (value: T, index: number, array: T[]) => boolean }
	| { type: 'update'; value: Partial<T>; findFn: (value: T, index: number, array: T[] | readonly T[]) => boolean }
	| { type: 'validate' | 'save' | 'reset' };
export type ArrayReducerDispatch<T> = Dispatch<ArrayReducerActionType<T>>;

export type ArrayReducerOptions<T = unknown> = {
	onAdd?: (data: T) => void;
	onValidate?: (data: T[] | readonly T[]) => void;
	onSave?: (data: T[] | readonly T[]) => void;
	onReset?: () => void;
};
export type ArrayReducerReturnType<T = unknown> = [T[] | undefined, ArrayReducerDispatch<T>];

/**
 * Hook feito pra abstrair a lógica de manuseio de dados de um formulário,
 * com métodos para atualizar os dados, resetar aos valores iniciais, validar o formulário e salvar.
 *
 * @param initialData Array que contém os dados do formulário.
 * @param options Opções e callbacks, como `onSave` e `onValidate`
 * @returns Mesmo retorno de `useReducer`
 */
export const useFormReducerFromArray = <T>(initialData?: T[] | readonly T[], options?: ArrayReducerOptions<T>) => {
	const reducer = (state: T[] | readonly T[] | undefined, action: ArrayReducerActionType<T>) => {
		let newState: T[];

		switch (action.type) {
			case 'add': {
				newState = [...(state || []), action.value];
				options?.onAdd?.(action.value);
				return newState;
			}

			case 'update': {
				newState = [...(state || [])];
				const index = newState.findIndex(action.findFn);

				if (index !== undefined && index >= 0 && state) {
					const item = newState[index];
					newState[index] = { ...item, ...action.value };
				}

				return newState;
			}

			case 'remove': {
				newState = state?.filter((item, index, array) => !action.findFn(item, index, array as T[])) || [];
				return newState;
			}

			case 'validate': {
				options?.onValidate?.(state || []);
				return state;
			}

			case 'reset': {
				options?.onReset?.();
				return initialData;
			}

			case 'save': {
				options?.onSave?.(state || []);
				return state;
			}
		}
	};

	const [state, dispatch] = useReducer(reducer, initialData);

	// Qualquer alteração nos dados iniciais gera um evento "reset"
	// Dessa forma, um `refetch` na query de origem dos dados vai gerar um update nan interface
	useEffect(() => {
		if (!initialData) return;
		dispatch({ type: 'reset' });
	}, [initialData]);

	// É necessário "castar" o tipo, já que retornamos um array [valor, outroValor]
	// Comportamento padrão do TS é considerar esse retorno como Array<valor | outroValor>
	return [state, dispatch] as ArrayReducerReturnType<T>;
};

/**
 * Hook que retorna se o participante é assistido ou não.
 *
 * Depende do `tipoParticipante` do hook `useIdentityContext`!
 *
 * @returns `true` ou `false`
 */
export const useIsAssistido = () => {
	const { tipoParticipante } = useParticipanteIdentityContext();

	const isAssistido =
		tipoParticipante === TipoParticipanteEnum.RendaFinanceiraProgressivaComEmprestimo ||
		tipoParticipante === TipoParticipanteEnum.RendaFinanceiraProgressivaSemEmprestimo ||
		tipoParticipante === TipoParticipanteEnum.VitalicioComEmprestimo ||
		tipoParticipante === TipoParticipanteEnum.VitalicioSemEmprestimo ||
		tipoParticipante === TipoParticipanteEnum.PensionistaRegressivo ||
		tipoParticipante === TipoParticipanteEnum.PensionistaProgressivo ||
		tipoParticipante === TipoParticipanteEnum.DesligadoComEmprestimo ||
		tipoParticipante === TipoParticipanteEnum.AutopatrocinadoComEmprestimo ||
		tipoParticipante === TipoParticipanteEnum.AutopatrocinadoSemEmprestimo;

	return isAssistido;
};

/**
 * Hook que retorna se o participante é autopatrocinado ou não.
 *
 * Depende do `tipoParticipante` do hook `useIdentityContext`!
 *
 * @returns `true` ou `false`
 */
export const useIsAutopatrocinado = () => {
	const { tipoParticipante } = useParticipanteIdentityContext();

	const isAutopatrocinado =
		tipoParticipante === TipoParticipanteEnum.AutopatrocinadoComEmprestimo ||
		tipoParticipante === TipoParticipanteEnum.AutopatrocinadoSemEmprestimo;

	return isAutopatrocinado;
};

/**
 * Hook que retorna se o participante é BPD ou não.
 *
 * Depende do `tipoParticipante` do hook `useIdentityContext`!
 *
 * @returns `true` ou `false`
 */
export const useIsBPD = () => {
	const { tipoParticipante } = useParticipanteIdentityContext();

	const isBPD =
		tipoParticipante === TipoParticipanteEnum.BPDComEmprestimo ||
		tipoParticipante === TipoParticipanteEnum.BPDSemEmprestimo;

	return isBPD;
};

const { useBreakpoint } = Grid;

/**
 * Hook que informa se a tela atual é mobile ou não.
 *
 * Baseia-se no hook `useBreakpoint` da lib `antd`.
 * @returns `boolean`
 */
export const useIsMobile = () => {
	const breakpoints = useBreakpoint();
	// Caso estejamos no breakpoint Large, já consideramos que é uma tela Desktop
	const isDesktop = breakpoints.lg;

	// Valor 'default' é false
	const isMobile = isDesktop === undefined ? false : !isDesktop;

	return isMobile;
};

/**
 * Hook que retorna o env atual.
 *
 * @returns `"development"` | `"production"` | `"test"`
 */
export const useNodeEnv = () => {
	const env = process.env.NODE_ENV;

	return env;
};

export type ValidationMessagesType<T> = Partial<Record<keyof T, string>>;
export type UseValidationValidateCb<T> = (result: ValidationResult<T>) => ValidationMessagesType<T>;
export type UseValidationArgs<T> = {
	validator: Validator<T>;
	fields: ValidationFields<T>;

	onValidate?: UseValidationValidateCb<T>;
};
/**
 * Hook que age como um helper para validação de campos + exibição de mensagens de erro.
 *
 * SEMPRE MEMOIZE OS PARÂMETROS DESSE HOOK!
 * @returns
 */
export const useValidation = <T>({ validator, fields, onValidate }: UseValidationArgs<T>) => {
	const [messages, setMessages] = useState<ValidationMessagesType<T>>({});
	const [isValid, setIsValid] = useState(true);

	useEffect(() => {
		const result = validator.validateFields(fields);
		setIsValid(result.isValid);

		if (onValidate) setMessages(onValidate?.(result));
	}, [validator, fields, onValidate]);

	return { messages, isValid };
};

export * from './skeleton';
