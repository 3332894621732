import { memo } from 'react';
import { Route, Routes } from 'react-router-dom';

import { Dashboard } from './dashboard';
import { TelaParticipante } from './Participante/index';

export const Admin = memo(() => {
	return (
		<Routes>
			<Route path='/' element={<Dashboard />} />
			<Route path='/participantes' element={<TelaParticipante />} />
		</Routes>
	);
});
