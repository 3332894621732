import { ISpinProps, Skeleton, Spin } from '@jcm/design-system';
import { SkeletonInputProps } from 'antd/es/skeleton/Input';
import { useCallback } from 'react';

import { UseQueryByIdFn, UseQueryByNullableIdFn, UseQueryFn } from 'queries';

type QueryFnType = UseQueryFn | UseQueryByIdFn | UseQueryByNullableIdFn;
type QueryResultType = ReturnType<QueryFnType>;
/**
 * Hook feito pra ajudar na criação de componentes com estado de carregamento.
 *
 * Exibição do Skeleton depende do atributo `isPending` do primeiro argumento.
 */
export const useSkeletonHelper = ({ isPending }: Partial<QueryResultType>) => {
	const getValueOrSkeleton = useCallback(
		(value: React.ReactNode, skeletonProps?: SkeletonInputProps): React.ReactNode => {
			return isPending ? <Skeleton.Input active {...skeletonProps} /> : value;
		},
		[isPending],
	);

	const getValueOrSpin = useCallback(
		(value: React.ReactNode, spinProps?: ISpinProps): React.ReactNode => {
			return isPending ? <Spin variant='default' {...spinProps} /> : value;
		},
		[isPending],
	);

	type ReturnType = {
		/**
		 * Função que retorna um Skeleton de acordo com estado da query.
		 *
		 * Aceita props para o Skeleton como segundo argumento.
		 */
		getValueOrSkeleton: typeof getValueOrSkeleton;
		/**
		 * Função que retorna um Spin de acordo com estado da query.
		 *
		 * Aceita props para o Spin como segundo argumento.
		 */
		getValueOrSpin: typeof getValueOrSpin;
	};

	return { getValueOrSkeleton, getValueOrSpin } as ReturnType;
};
