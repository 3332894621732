import { Button, Flex, Surface, Typography, useTheme } from '@jcm/design-system';
import { memo } from 'react';

import { DashboardCard } from './DashboardCard';

import avestruz from 'assets/images/avestruz.svg';

type Props = {
	id?: string;
	title?: string;
	icon?: React.ReactNode;
	loading?: boolean;
	refetch: () => void;
};
export const ErrorDashboardCard = memo<Props>(({ id, refetch, title, icon, loading }) => {
	const { shapes } = useTheme();

	return (
		<DashboardCard id={id} icon={icon} title={title}>
			<Flex gap={shapes.sizes.large} justify='center' align='center' flex={1} vertical>
				<Surface
					variant='default'
					level={1}
					style={{
						display: 'flex',
						flex: 1,
						width: '100%',
						alignItems: 'center',
						justifyContent: 'center',
						padding: '3rem',
					}}
				>
					<img src={avestruz} alt='' id='img-avestruz' style={{ height: '-webkit-fill-available' }} />
				</Surface>

				<Typography.Title variant='error' size='medium'>
					Algo deu errado!
				</Typography.Title>

				<Button
					variant='default'
					type='filled-tonal'
					onClick={() => refetch()}
					id='btn-tentar-novamente'
					loading={loading}
				>
					Tentar novamente
				</Button>
			</Flex>
		</DashboardCard>
	);
});
