import { memo } from 'react';

import { useIsMobile } from 'hooks';
import { useParticipacoesPlanosQuery } from 'queries/participante';

import { SeletorParticipacaoDesktop } from './Desktop';
import { SeletorParticipacaoMobile } from './Mobile';

export interface ISeletorParticipacaoProps {
	idParticipacaoAtiva?: number;
	queryFn: typeof useParticipacoesPlanosQuery;
	onParticipacaoChange: (value: number) => void;
}
export const SeletorParticipacao = memo<ISeletorParticipacaoProps>((props) => {
	const isMobile = useIsMobile();

	const Component = isMobile ? SeletorParticipacaoMobile : SeletorParticipacaoDesktop;

	return <Component {...props} />;
});
