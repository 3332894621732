import { Button, Card, Col, Flex, Row, TextInput, Typography, useTheme } from '@jcm/design-system';
import { Slider } from 'antd';
import { memo, useState, useEffect } from 'react';

import { AlteracaoCusteioConfigConta } from 'api';
import { NumberFormatter } from 'lib';

type AlteracaoCusteioCardProps = {
	configConta: AlteracaoCusteioConfigConta;
	salarioParticipacao: number;
	porcentagemContrapartida: number;
	onTotalPorcentagemChange?: (isTotal: boolean) => void;
};

export const AlteracaoCusteioCard: React.FC<AlteracaoCusteioCardProps> = memo(
	({ onTotalPorcentagemChange, configConta }) => {
		const [porcentagem, setPorcentagem] = useState(0);

		const { colors, elevation, shapes } = useTheme();

		useEffect(() => {
			// TODO: Ajustar a lógica postarior a implementação da api
			if (onTotalPorcentagemChange) {
				onTotalPorcentagemChange(porcentagem === 1);
			}
		}, [porcentagem, onTotalPorcentagemChange]);

		return (
			<Card
				style={{
					backgroundColor: colors.containerLowest,
					borderRadius: shapes.sizes.medium,
					padding: shapes.sizes.large,
					gap: shapes.sizes.large,
					boxShadow: elevation[1],
					width: '100%',
					display: 'flex',
					flexDirection: 'column',
				}}
			>
				<Flex style={{ color: colors.primary }}>{configConta.contribuicaoPlano}</Flex>
				<Flex vertical style={{ gap: shapes.sizes.large }}>
					<Row gutter={[16, 16]}>
						<Col xs={24} xxl={12}>
							<Flex vertical>
								<Typography.Body>Contribuição total no mês</Typography.Body>
								<Typography.Title style={{ fontWeight: 700 }}>
									{NumberFormatter.format(configConta.contribuicaoTotalNoMes, 'BRL')}
								</Typography.Title>
							</Flex>
						</Col>

						<Col xs={24} xxl={12}>
							<Flex vertical>
								<Typography.Body>Percentual atual</Typography.Body>
								<Typography.Title style={{ fontWeight: 700 }}>
									{NumberFormatter.format(configConta.percentualAtual, '%')}
								</Typography.Title>
							</Flex>
						</Col>

						<Col xs={24} xxl={12}>
							<Flex vertical>
								<Typography.Body>Valor atual</Typography.Body>
								<Typography.Title style={{ fontWeight: 700 }}>
									{NumberFormatter.format(configConta.valorAtual, 'BRL')}
								</Typography.Title>
							</Flex>
						</Col>

						<Col xs={24} xxl={12}>
							<Flex vertical>
								<Typography.Body>Contrapartida atual</Typography.Body>
								<Typography.Title style={{ fontWeight: 700 }}>
									{NumberFormatter.format(configConta.contrapartidaAtual, 'BRL')}
								</Typography.Title>
							</Flex>
						</Col>
					</Row>

					<Typography.Body style={{ color: colors.onSurfaceVariant }}>
						Arraste o slider para selecionar o percentual desejado ou digite o valor para prosseguir.
					</Typography.Body>

					<Row gutter={[16, 16]}>
						<Col xs={12} xxl={6}>
							<Flex vertical>
								<Typography.Body>Percentual</Typography.Body>
								<TextInput
									type='number'
									placeholder='99,99%'
									// Próximas 3 props vêm da API
									max={1}
									step={0.1}
									value={porcentagem}
									onChange={(e) => setPorcentagem(Number(e.target.value))}
								/>
							</Flex>
						</Col>
						<Col xs={24} xxl={18}>
							<Slider
								// Próximas 4 props vêm da API
								marks={{ 0: '0%', 1: '100%' }}
								min={0}
								max={1}
								step={0.1}
								value={porcentagem}
								onChange={(e) => setPorcentagem(e)}
							/>
						</Col>

						<Col xs={9}>
							<Flex vertical>
								<Typography.Body>Novo valor</Typography.Body>
								<Typography.Title style={{ fontWeight: 700 }}>R$ 565,00</Typography.Title>
							</Flex>
						</Col>

						<Col xs={15}>
							<Flex vertical>
								<Typography.Body>Contribuição Normal</Typography.Body>
								<Typography.Title style={{ fontWeight: 700 }}>R$ 400,00</Typography.Title>
							</Flex>
						</Col>
					</Row>

					<Flex justify='end' style={{ width: '100%' }}>
						<Button type='text' variant='error' style={{ width: 'auto' }}>
							Limpar Valores
						</Button>
					</Flex>
				</Flex>
			</Card>
		);
	},
);
