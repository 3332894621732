type RotaParticipante =
	| 'home'
	| 'saldo'
	| 'contribuicao'
	| 'rentabilidade'
	| 'portabilidade'
	| 'beneficios'
	| 'simulacao'
	| 'requerimentos'
	| 'emprestimo'
	| 'sac'
	| 'dadosCadastrais';

/**
 * Classe que abriga as rotas principais do portal do participante, pra facilitar a navegação
 *
 * Rotas dinâmicas não são incluídas aqui. Rotas não são matchers.
 */
export class RotasParticipante {
	static readonly rotas: Record<RotaParticipante, string> = {
		home: '/participante',
		saldo: '/participante/saldo',
		contribuicao: '/participante/contribuicao',
		rentabilidade: '/participante/rentabilidade',
		portabilidade: '/participante/portabilidade',
		beneficios: '/participante/beneficios',
		simulacao: '/participante/simulacao',
		requerimentos: '/participante/requerimentos',
		emprestimo: '/participante/emprestimo',
		sac: '/participante/sac',
		dadosCadastrais: '/participante/dados-cadastrais',
	};

	static get = (base: RotaParticipante, ...args: string[]) => {
		return [this.rotas[base], ...args].join('/');
	};
}
