import { DesktopLayout, Flex, NavigationBar, Skeleton, useTheme } from '@jcm/design-system';
import { memo } from 'react';

import { SeletorParticipacao, SiderMenu, ToggleVisibilityButton } from 'components';
import { NotificationsIconButton, SearchIconButton, ToggleThemeIconButton } from 'components/header';
import { useParticipanteIdentityContext } from 'context';
import { useNavigateWithSearchParams } from 'hooks';
import { useNotificacoesQuery } from 'queries/participante';

import { ILayoutVariantProps } from '.';

interface IDesktopProps extends ILayoutVariantProps {}
export const Desktop = memo<IDesktopProps>(
	({ id, drawerBarItems, participacaoAtiva, children, queryFn, onParticipacaoChange }) => {
		const { shapes } = useTheme();
		const navigateWithSearchParams = useNavigateWithSearchParams();

		const { idParticipacao } = useParticipanteIdentityContext();
		const { data, isPending, isError } = queryFn(idParticipacao);

		const getContentOrSkeleton = (content: React.ReactNode) =>
			isPending ? (
				<div style={{ width: '100%' }}>
					<Skeleton.Input active style={{ width: '100%' }} />
				</div>
			) : (
				content
			);

		const headerContent = getContentOrSkeleton(
			data && !isError && (
				<Flex vertical gap={shapes.sizes.extraSmall}>
					<SeletorParticipacao
						idParticipacaoAtiva={participacaoAtiva?.participacaoId}
						onParticipacaoChange={onParticipacaoChange}
						queryFn={queryFn}
					/>
				</Flex>
			),
		);

		const headerIcons = (
			<>
				<ToggleVisibilityButton loading={isPending} />
				<SearchIconButton />
				<NotificationsIconButton useQueryFn={useNotificacoesQuery} />
				<ToggleThemeIconButton />
			</>
		);

		return (
			<DesktopLayout
				id={id}
				sider={
					<SiderMenu
						items={drawerBarItems}
						onSelect={(info) => navigateWithSearchParams(info.key)}
						nome={participacaoAtiva?.nome.split(' ')[0]}
						status={participacaoAtiva?.status}
					/>
				}
				header={<NavigationBar container='surface' content={headerContent} icons={headerIcons} />}
			>
				{children}
			</DesktopLayout>
		);
	},
);
