import { useMutation, useQuery } from '@tanstack/react-query';

import { IKeycloakContext, useKeycloakContext, useQueryClientContext } from 'context';
import { useApi, useGlobalConfigFile, useNodeEnv } from 'hooks';

import {
	GetBeneficiariosReturnType,
	GetBeneficiosContrachequeReturnType,
	GetBeneficiosSaldoDetalhadoReturnType,
	GetBeneficiosSaldosReturnType,
	PostCalculoInstitutosLegaisArgs,
	PostCalculoInstitutosLegaisReturnType,
	GetComposicaoSaldoReturnType,
	GetContribuicoesAlteracaoCusteioPlanoBasicoReturnType,
	GetContribuicoesReturnType,
	GetEmprestimoReturnType,
	GetExtratoContribuicaoArgs,
	GetExtratoContribuicaoReturnType,
	GetFichaFinanceiraAssistidoArgs,
	GetFichaFinanceiraAssistidoReturnType,
	GetHistoricoMensalContribuicoesReturnType,
	GetInformacoesTipoContaBancariaReturnType,
	GetInformacoesTipoEstadoCivilReturnType,
	GetInformacoesTipoSexoReturnType,
	GetMinhaRentabilidadeReturnType,
	GetNotificacoesReturnType,
	GetParticipacoesBeneficiosReturnType,
	GetParticipacoesComposicaoSaldoReturnType,
	GetParticipacoesDadosContaBancariaReturnType,
	GetParticipacoesDadosContatoReturnType,
	GetParticipacoesDadosPessoaisReturnType,
	GetParticipacoesDadosPlanosReturnType,
	GetParticipacoesPlanosReturnType,
	GetParticipacoesPpeReturnType,
	GetParticipacoesReturnType,
	GetParticipacoesSaldoExtratoArgs,
	GetParticipacoesSaldoExtratoReturnType,
	GetParticipacoesSaldoReturnType,
	GetParticipacoesUsPersonReturnType,
	GetSimulacaoSaldoReturnType,
	GetTotaisContribuicoesReturnType,
	IParticipanteApi,
	ParticipanteApi,
	PostParticipacoesDadosContaBancariaPayloadType,
	PostContribuicoesAlteracaoCusteioPlanoBasicoPayloadType,
	PostParticipacoesDadosContatoEmailPayloadType,
	PostParticipacoesDadosContatoEnderecoPayloadType,
	PostParticipacoesDadosContatoTelefonePayloadType,
	PostParticipacoesDadosPessoaisPayloadType,
	GetDadosSimulacaoBeneficioReturnType,
	GetDadosSimulacaoBeneficioArgs,
} from 'api';
import { ParticipanteApi as MockParticipanteApi } from 'api/__mocks__';
import {
	MutationOptions,
	QueryByIdArgs,
	QueryByNullableIdArgs,
	UseMutationByNullableIdFn,
	UseMutationWithCustomArgsByNullableIdFn,
	UseQueryByNullableIdFn,
	UseQueryFn,
	UseQueryWithCustomArgsFn,
} from 'queries';

/**
 * Hook que retorna uma instância da ApiParticipante.
 *
 * @param kcContext Contexto do Keycloak
 * @param options
 * @returns `ParticipanteApi`, versão mockada em desenvolvimento
 */
export const useParticipanteApi = (
	kcContext: IKeycloakContext,
	options?: { isProduction?: boolean },
): IParticipanteApi => {
	const nodeEnv = useNodeEnv();
	const { apiConfig } = useGlobalConfigFile();

	const axiosApi = useApi({ baseURL: apiConfig.url, headers: { Authorization: `Bearer ${kcContext.accessToken}` } });

	const apiProps = { api: axiosApi, clienteId: apiConfig.clienteId };
	const mockApiProps = { delay: 1000 };

	if (options?.isProduction !== undefined) {
		return options.isProduction ? new ParticipanteApi(apiProps) : new MockParticipanteApi(mockApiProps);
	}

	return nodeEnv === 'production' ? new ParticipanteApi(apiProps) : new MockParticipanteApi(mockApiProps);
};

// TODO - Todas as rotas relacionadas ao dashboard
// componente -> beneficiários
export const useBeneficiariosQuery: UseQueryByNullableIdFn<GetBeneficiariosReturnType[]> = (id, options) => {
	const kcContext = useKeycloakContext();
	const participanteApi = useParticipanteApi(kcContext, options);

	return useQuery({
		queryKey: ['beneficiarios', id, ...(options?.keys ?? [])],
		queryFn: () => (id ? participanteApi.getBeneficiariosById(id) : null),
		enabled: kcContext.isAuthenticated,
	});
};

// componente -> Contribuições
export const useTotaisContribuicoesQuery: UseQueryByNullableIdFn<GetTotaisContribuicoesReturnType> = (id, options) => {
	const kcContext = useKeycloakContext();
	const participanteApi = useParticipanteApi(kcContext, options);

	return useQuery({
		queryKey: ['totaisContribuicoes', id, ...(options?.keys ?? [])],
		queryFn: () => (id ? participanteApi.getTotaisContribuicoesById(id) : null),
		enabled: kcContext.isAuthenticated && id !== null,
	});
};

// componente -> Simulação
export const useSimulacaoSaldoQuery: UseQueryByNullableIdFn<GetSimulacaoSaldoReturnType> = (id, options) => {
	const kcContext = useKeycloakContext();
	// TODO - Desmockar
	const participanteApi = useParticipanteApi(kcContext, { isProduction: false });

	return useQuery({
		queryKey: ['simulacao', id, ...(options?.keys ?? [])],
		queryFn: () => (id ? participanteApi.getSimulacaoSaldosById(id) : null),
		enabled: kcContext.isAuthenticated,
	});
};

// componente -> Minha Rentabilidade
export const useMinhaRentabilidadeQuery: UseQueryByNullableIdFn<GetMinhaRentabilidadeReturnType> = (id, options) => {
	const kcContext = useKeycloakContext();
	// TODO - Desmockar
	const participanteApi = useParticipanteApi(kcContext, { isProduction: false });

	return useQuery({
		queryKey: ['minha-rentabilidade', id, ...(options?.keys ?? [])],
		queryFn: () => (id ? participanteApi.getMinhaRentabilidadeById(id) : null),
		enabled: kcContext.isAuthenticated,
	});
};

// componente -> Empréstimo
export const useEmprestimoQuery: UseQueryByNullableIdFn<GetEmprestimoReturnType> = (id, options) => {
	const kcContext = useKeycloakContext();
	// TODO - Desmockar
	const participanteApi = useParticipanteApi(kcContext, { isProduction: false });

	return useQuery({
		queryKey: ['emprestimo', id, ...(options?.keys ?? [])],
		queryFn: () => (id ? participanteApi.getEmprestimoById(id) : null),
		enabled: kcContext.isAuthenticated,
	});
};

// componente -> Composição do Saldo
export const useComposicaoSaldoQuery: UseQueryByNullableIdFn<GetComposicaoSaldoReturnType> = (id, options) => {
	const kcContext = useKeycloakContext();
	const participanteApi = useParticipanteApi(kcContext, options);

	return useQuery({
		queryKey: ['composicao-saldo', id, ...(options?.keys ?? [])],
		queryFn: () => (id ? participanteApi.getComposicaoSaldoById(id) : null),
		enabled: kcContext.isAuthenticated,
	});
};

export const useContribuicoesQuery: UseQueryByNullableIdFn<GetContribuicoesReturnType> = (id, options) => {
	const kcContext = useKeycloakContext();
	const participanteApi = useParticipanteApi(kcContext, options);

	return useQuery({
		queryKey: ['contribuicoes', id, ...(options?.keys ?? [])],
		queryFn: () => (id ? participanteApi.getContribuicoesById(id) : null),
		enabled: kcContext.isAuthenticated && id !== null,
	});
};

export const useHistoricoMensalContribuicoesQuery: UseQueryByNullableIdFn<GetHistoricoMensalContribuicoesReturnType> = (
	id,
	options,
) => {
	const kcContext = useKeycloakContext();
	const participanteApi = useParticipanteApi(kcContext, options);

	return useQuery({
		queryKey: ['historicoMensalContribuicoes', id, ...(options?.keys ?? [])],
		queryFn: () => (id ? participanteApi.getHistoricoMensalContribuicoesById(id) : null),
		enabled: kcContext.isAuthenticated && id !== null,
	});
};

export const useAlteracaoCusteioPlanoBasicoQuery: UseQueryByNullableIdFn<
	GetContribuicoesAlteracaoCusteioPlanoBasicoReturnType
> = (id, options) => {
	const kcContext = useKeycloakContext();
	const participanteApi = useParticipanteApi(kcContext, options);

	return useQuery({
		queryKey: ['contribuicoes', 'alteracao-custeio', id, ...(options?.keys ?? [])],
		queryFn: () => (id ? participanteApi.getContribuicoesAlteracaoCusteioPlanoBasicoById(id) : null),
		enabled: kcContext.isAuthenticated && id !== null,
	});
};

// Benefícios
export const useBeneficiosSaldosQuery: UseQueryByNullableIdFn<GetBeneficiosSaldosReturnType> = (id, options) => {
	const kcContext = useKeycloakContext();
	const participanteApi = useParticipanteApi(kcContext, options);

	return useQuery({
		queryKey: ['benefícios', 'saldos', id, ...(options?.keys ?? [])],
		queryFn: () => (id ? participanteApi.getBeneficiosSaldosById(id) : null),
		enabled: kcContext.isAuthenticated,
	});
};

export const useBeneficiosContrachequeQuery: UseQueryByNullableIdFn<GetBeneficiosContrachequeReturnType[]> = (
	id,
	options,
) => {
	const kcContext = useKeycloakContext();
	const participanteApi = useParticipanteApi(kcContext, options);

	return useQuery({
		queryKey: ['benefícios', 'contracheque', id, ...(options?.keys ?? [])],
		queryFn: () => (id ? participanteApi.getBeneficiosContrachequeById(id) : null),
		enabled: kcContext.isAuthenticated,
	});
};

export const useBeneficiosSaldoDetalhadoQuery: UseQueryByNullableIdFn<GetBeneficiosSaldoDetalhadoReturnType> = (
	id,
	options,
) => {
	const kcContext = useKeycloakContext();
	const participanteApi = useParticipanteApi(kcContext, options);

	return useQuery({
		queryKey: ['benefícios', 'saldoDetalhado', id, ...(options?.keys ?? [])],
		queryFn: () => (id ? participanteApi.getBeneficiosSaldoDetalhadoById(id) : null),
		enabled: kcContext.isAuthenticated,
	});
};

export const useFichaFinanceiraAssistidoQuery: UseQueryWithCustomArgsFn<
	GetFichaFinanceiraAssistidoReturnType[],
	QueryByIdArgs<GetFichaFinanceiraAssistidoArgs>
> = (args, options) => {
	const kcContext = useKeycloakContext();
	const participanteApi = useParticipanteApi(kcContext, options);

	const { id, ...requestArgs } = args;

	return useQuery({
		queryKey: ['fichaFinanceira', 'assistido', args, ...(options?.keys ?? [])],
		queryFn: () => participanteApi.getFichaFinanceiraAssistidoById(id, requestArgs),
		enabled: kcContext.isAuthenticated,
	});
};

// Participações
export const useParticipacoesQuery: UseQueryByNullableIdFn<GetParticipacoesReturnType> = (id, options) => {
	const kcContext = useKeycloakContext();
	const participanteApi = useParticipanteApi(kcContext, options);

	return useQuery({
		queryKey: ['participações', id, ...(options?.keys ?? [])],
		queryFn: () => (id ? participanteApi.getParticipacoesById(id) : null),
		enabled: kcContext.isAuthenticated && id !== null,
	});
};

export const useParticipacoesSaldoQuery: UseQueryByNullableIdFn<GetParticipacoesSaldoReturnType> = (id, options) => {
	const kcContext = useKeycloakContext();
	const participanteApi = useParticipanteApi(kcContext, options);

	return useQuery({
		queryKey: ['participações', 'saldo', id, ...(options?.keys ?? [])],
		queryFn: () => (id ? participanteApi.getParticipacoesSaldoById(id) : null),
		enabled: kcContext.isAuthenticated && id !== null,
	});
};

export const useParticipacoesComposicaoQuery: UseQueryByNullableIdFn<GetParticipacoesComposicaoSaldoReturnType> = (
	id,
	options,
) => {
	const kcContext = useKeycloakContext();
	const participanteApi = useParticipanteApi(kcContext, options);

	return useQuery({
		queryKey: ['participações', 'composição', id, ...(options?.keys ?? [])],
		queryFn: () => (id ? participanteApi.getParticipacoesComposicaoById(id) : null),
		enabled: kcContext.isAuthenticated && id !== null,
	});
};

export const useParticipacoesBeneficiosQuery: UseQueryByNullableIdFn<GetParticipacoesBeneficiosReturnType[]> = (
	id,
	options,
) => {
	const kcContext = useKeycloakContext();
	const participanteApi = useParticipanteApi(kcContext, options);

	return useQuery({
		queryKey: ['participações', 'benefícios', id, ...(options?.keys ?? [])],
		queryFn: () => participanteApi.getParticipacoesBeneficiosById(id as string | number),
		enabled: kcContext.isAuthenticated && id !== null,
	});
};

export const useParticipacoesDadosPessoaisQuery: UseQueryByNullableIdFn<GetParticipacoesDadosPessoaisReturnType> = (
	id,
	options,
) => {
	const kcContext = useKeycloakContext();
	const participanteApi = useParticipanteApi(kcContext, options);

	return useQuery({
		queryKey: ['dados-pessoais', id, ...(options?.keys ?? [])],
		queryFn: () => (id ? participanteApi.getParticipacoesDadosPessoaisById(id) : null),
		enabled: kcContext.isAuthenticated && id !== null,
	});
};

export const useParticipacoesDadosContaBancariaQuery: UseQueryByNullableIdFn<
	readonly GetParticipacoesDadosContaBancariaReturnType[]
> = (id, options) => {
	const kcContext = useKeycloakContext();
	const participanteApi = useParticipanteApi(kcContext, options);

	return useQuery({
		queryKey: ['conta-bancaria', id, ...(options?.keys ?? [])],
		queryFn: () => (id ? participanteApi.getParticipacoesDadosContaBancariaById(id) : null),
		enabled: kcContext.isAuthenticated && id !== null,
	});
};

export const useParticipacoesContatoQuery: UseQueryByNullableIdFn<GetParticipacoesDadosContatoReturnType> = (
	id,
	options,
) => {
	const kcContext = useKeycloakContext();
	const participanteApi = useParticipanteApi(kcContext, options);

	return useQuery({
		queryKey: ['contato', id, ...(options?.keys ?? [])],
		queryFn: () => (id ? participanteApi.getParticipacoesDadosContatoById(id) : null),
		enabled: kcContext.isAuthenticated,
	});
};

export const useParticipacoesDadosPlanosQuery: UseQueryByNullableIdFn<GetParticipacoesDadosPlanosReturnType> = (
	id,
	options,
) => {
	const kcContext = useKeycloakContext();
	const participanteApi = useParticipanteApi(kcContext, options);

	return useQuery({
		queryKey: ['dados-planos', id, ...(options?.keys ?? [])],
		queryFn: () => (id ? participanteApi.getParticipacoesDadosPlanosById(id) : null),
		enabled: kcContext.isAuthenticated,
	});
};

export const useParticipacoesSaldoExtratoQuery: UseQueryWithCustomArgsFn<
	GetParticipacoesSaldoExtratoReturnType,
	QueryByNullableIdArgs<GetParticipacoesSaldoExtratoArgs>
> = (args, options) => {
	const kcContext = useKeycloakContext();
	const participanteApi = useParticipanteApi(kcContext, options);

	const { id, ...requestArgs } = args;

	return useQuery({
		queryKey: ['participações', 'saldo', 'extrato', id, requestArgs, ...(options?.keys ?? [])],
		queryFn: () => participanteApi.getParticipacoesSaldoExtratoById(id as string | number, requestArgs),
		enabled: kcContext.isAuthenticated && id !== null,
	});
};

// Header de Planos
export const useParticipacoesPlanosQuery: UseQueryByNullableIdFn<GetParticipacoesPlanosReturnType[]> = (
	id,
	options,
) => {
	const kcContext = useKeycloakContext();
	const participanteApi = useParticipanteApi(kcContext, options);

	return useQuery({
		queryKey: ['participacoes', 'planos', id, ...(options?.keys ?? [])],
		queryFn: () => participanteApi.getParticipacoesPlanosById(id),
		enabled: kcContext.isAuthenticated,
	});
};

// Extrato Contribuição (Movimentos)
export const useExtratoContribuicaoQuery: UseQueryWithCustomArgsFn<
	GetExtratoContribuicaoReturnType[],
	QueryByNullableIdArgs<GetExtratoContribuicaoArgs>
> = (args, options) => {
	const kcContext = useKeycloakContext();
	const participanteApi = useParticipanteApi(kcContext, options);

	const { id, ...requestArgs } = args;

	return useQuery({
		queryKey: ['extratoMovimento', id, requestArgs, ...(options?.keys ?? [])],
		queryFn: () => participanteApi.getExtratoContribuicaoById(id as string | number, requestArgs),
		enabled:
			kcContext.isAuthenticated && id !== null && requestArgs.filtro === 'Custom'
				? args.dataInicio !== null && args.dataFim !== null
				: requestArgs.filtro !== null,
	});
};

export const useNotificacoesQuery: UseQueryFn<GetNotificacoesReturnType[]> = (options) => {
	const kcContext = useKeycloakContext();
	const participanteApi = useParticipanteApi(kcContext, options);

	return useQuery({
		queryKey: ['notificacoes', ...(options?.keys ?? [])],
		queryFn: () => participanteApi.getNotificacoes(),
		enabled: kcContext.isAuthenticated,
	});
};

export const usePpeQuery: UseQueryByNullableIdFn<GetParticipacoesPpeReturnType> = (id, options) => {
	const kcContext = useKeycloakContext();
	const participanteApi = useParticipanteApi(kcContext, options);

	return useQuery({
		queryKey: ['ppe', ...(options?.keys ?? [])],
		queryFn: () => (id ? participanteApi.getParticipacoesPpeById(id) : null),
		enabled: kcContext.isAuthenticated,
	});
};

export const useUsPersonQuery: UseQueryByNullableIdFn<GetParticipacoesUsPersonReturnType> = (id, options) => {
	const kcContext = useKeycloakContext();
	const participanteApi = useParticipanteApi(kcContext, options);

	return useQuery({
		queryKey: ['us-person', ...(options?.keys ?? [])],
		queryFn: () => (id ? participanteApi.getParticipacoesUsPersonById(id) : null),
		enabled: kcContext.isAuthenticated,
	});
};

// Informações

export const useTipoSexoQuery: UseQueryFn<readonly GetInformacoesTipoSexoReturnType[]> = (options) => {
	const kcContext = useKeycloakContext();
	const participanteApi = useParticipanteApi(kcContext, options);

	return useQuery({
		queryKey: ['tipo', 'sexo', ...(options?.keys ?? [])],
		queryFn: participanteApi.getInformacoesTipoSexo,
		enabled: kcContext.isAuthenticated,
	});
};

export const useTipoEstadoCivilQuery: UseQueryFn<readonly GetInformacoesTipoEstadoCivilReturnType[]> = (options) => {
	const kcContext = useKeycloakContext();
	const participanteApi = useParticipanteApi(kcContext, options);

	return useQuery({
		queryKey: ['tipo', 'estado-civil', ...(options?.keys ?? [])],
		queryFn: participanteApi.getInformacoesTipoEstadoCivil,
		enabled: kcContext.isAuthenticated,
	});
};

export const useTipoContaBancariaQuery: UseQueryFn<readonly GetInformacoesTipoContaBancariaReturnType[]> = (
	options,
) => {
	const kcContext = useKeycloakContext();
	const participanteApi = useParticipanteApi(kcContext, options);

	return useQuery({
		queryKey: ['tipo', 'conta-bancaria', ...(options?.keys ?? [])],
		queryFn: participanteApi.getInformacoesTipoContaBancaria,
		enabled: kcContext.isAuthenticated,
	});
};

export const useDadosSimulacaoBeneficioQuery: UseQueryWithCustomArgsFn<
	GetDadosSimulacaoBeneficioReturnType,
	QueryByNullableIdArgs<GetDadosSimulacaoBeneficioArgs>
> = (args, options) => {
	const kcContext = useKeycloakContext();
	const participanteApi = useParticipanteApi(kcContext, options);

	const { id, ...requestArgs } = args;

	return useQuery({
		queryKey: ['simulacao-beneficio', id, args.tipoBeneficioId, ...(options?.keys ?? [])],
		queryFn: () => (id ? participanteApi.getDadosSimulacaoBeneficio(id, requestArgs) : null),
		enabled: kcContext.isAuthenticated,
	});
};

// Mutations

/**
 * Helper que retorna uma função a ser utilizada como `mutationFn`.
 * O método checa se há um id e se há um payload, joga um erro caso não haja.
 *
 * @param id Identificador do recurso
 * @param cb Callback a ser executado depois da checagem
 * @returns Valor de `cb(id, payload)`
 * @throws `Error`, caso não haja id ou payload
 */
export const getMutationFn = <T>(id: string | number | null, cb: (id: string | number, payload: T) => Promise<T>) => {
	return (payload?: T) => {
		if (id && payload) return cb(id, payload);

		throw new Error('id ou payload ausentes: ' + JSON.stringify({ id, payload }));
	};
};

/**
 * Helper que retorna uma função a ser utilizada como `mutationFn`.
 * O método checa se há um id e se há um payload, joga um erro caso não haja.
 * Esse método é utilizada para casos onde é necessário se utilizar argumentos do tipo `K` e um retorno do tipo `T`
 *
 * @param id Identificador do recurso
 * @param cb Callback a ser executado depois da checagem
 * @returns Valor de `cb(id, payload)`
 * @throws `Error`, caso não haja id ou payload
 */
export const getMutationWithCustomArgsFn = <T, K>(
	id: string | number | null,
	cb: (id: string | number, payload: K) => Promise<T>,
) => {
	return (payload?: K) => {
		if (id && payload) return cb(id, payload);

		throw new Error('id ou payload ausentes: ' + JSON.stringify({ id, payload }));
	};
};

/**
 * Hook que retorna um objeto com opções para uma mutation
 */
const useMutationOptions = (invalidateKeys: unknown[], options?: MutationOptions): MutationOptions | undefined => {
	const queryClientContext = useQueryClientContext();

	if (!options) return;

	const successCb = () => {
		options.onSuccess?.();
		queryClientContext.invalidateQueries({ queryKey: invalidateKeys });
	};

	return {
		onMutate: options.onMutate,
		onSuccess: successCb,
		onError: options.onError,
		onSettled: options.onSettled,
	};
};

/**
 * Hook que gera e gerencia uma mutation relacionada à query de dados pessoais.
 *
 * @param id Identificador do recurso.
 * @param options
 * @returns
 */
export const useParticipacoesDadosPessoaisMutation: UseMutationByNullableIdFn<
	PostParticipacoesDadosPessoaisPayloadType
> = (id, options) => {
	const kcContext = useKeycloakContext();
	const participanteApi = useParticipanteApi(kcContext, options);
	const mutOptions = useMutationOptions(['dados-pessoais'], options);

	// TODO - Implementar a nova versão dessa rota
	return useMutation({
		mutationKey: ['dados-pessoais'],
		mutationFn: getMutationFn(id, participanteApi.postParticipacoesDadosPessoaisById),
		...mutOptions,
	});
};

/**
 * Hook que gera e gerencia uma mutation relacionada à query de contas bancárias.
 *
 * @param id Identificador do recurso.
 * @param options
 * @returns
 */
export const useParticipacoesDadosContaBancariaMutation: UseMutationByNullableIdFn<
	readonly PostParticipacoesDadosContaBancariaPayloadType[]
> = (id, options) => {
	const kcContext = useKeycloakContext();
	const participanteApi = useParticipanteApi(kcContext, options);
	const mutOptions = useMutationOptions(['conta-bancaria'], options);

	return useMutation({
		mutationKey: ['conta-bancaria'],
		mutationFn: getMutationFn(id, participanteApi.postParticipacoesDadosContaBancaria),
		...mutOptions,
	});
};

/**
 * Hook que gera e gerencia uma mutation relacionada à query de dados de plano.
 *
 * @param id Identificador do recurso.
 * @param options
 * @returns
 */
export const useParticipacoesDadosPlanosMutation: UseMutationByNullableIdFn<GetParticipacoesDadosPlanosReturnType> = (
	id,
	options,
) => {
	const kcContext = useKeycloakContext();
	const participanteApi = useParticipanteApi(kcContext, options);
	const mutOptions = useMutationOptions(['dados-planos'], options);

	return useMutation({
		mutationKey: ['dados-planos'],
		mutationFn: getMutationFn(id, participanteApi.putParticipacoesDadosPlanosById),
		...mutOptions,
	});
};

/**
 * Hook que gera e gerencia uma mutation relacionada à query de dados de contato.
 *
 * @param id Identificador do recurso.
 * @param options
 * @returns
 */
export const useParticipacoesDadosContatoMutation: UseMutationByNullableIdFn<GetParticipacoesDadosContatoReturnType> = (
	id,
	options,
) => {
	const kcContext = useKeycloakContext();
	const participanteApi = useParticipanteApi(kcContext, options);
	const mutOptions = useMutationOptions(['contato'], options);

	return useMutation({
		mutationKey: ['contato'],
		mutationFn: getMutationFn(id, participanteApi.putParticipacoesContatoById),
		...mutOptions,
	});
};

/**
 * Hook que gera e gerencia uma mutation relacionada à query de dados de contato.
 *
 * @param id Identificador do recurso.
 * @param options
 * @returns
 */
export const useUpdateParticipacoesDadosContatoTelefoneMutation: UseMutationByNullableIdFn<
	readonly PostParticipacoesDadosContatoTelefonePayloadType[]
> = (id, options) => {
	const kcContext = useKeycloakContext();
	const participanteApi = useParticipanteApi(kcContext, options);
	const mutOptions = useMutationOptions(['contato'], options);

	return useMutation({
		mutationKey: ['dados', 'contato', 'telefone'],
		mutationFn: getMutationFn(id, participanteApi.postParticipacoesDadosContatoTelefone),
		...mutOptions,
	});
};

/**
 * Hook que gera e gerencia uma mutation relacionada à query de dados de contato.
 *
 * @param id Identificador do recurso.
 * @param options
 * @returns
 */
export const useUpdateParticipacoesDadosContatoEmailMutation: UseMutationByNullableIdFn<
	readonly PostParticipacoesDadosContatoEmailPayloadType[]
> = (id, options) => {
	const kcContext = useKeycloakContext();
	const participanteApi = useParticipanteApi(kcContext, options);
	const mutOptions = useMutationOptions(['contato'], options);

	return useMutation({
		mutationKey: ['dados', 'contato', 'email'],
		mutationFn: getMutationFn(id, participanteApi.postParticipacoesDadosContatoEmail),
		...mutOptions,
	});
};

/**
 * Hook que gera e gerencia uma mutation relacionada à query de dados de contato.
 *
 * @param id Identificador do recurso.
 * @param options
 * @returns
 */
export const useUpdateParticipacoesDadosContatoEnderecoMutation: UseMutationByNullableIdFn<
	readonly PostParticipacoesDadosContatoEnderecoPayloadType[]
> = (id, options) => {
	const kcContext = useKeycloakContext();
	const participanteApi = useParticipanteApi(kcContext, options);
	const mutOptions = useMutationOptions(['contato'], options);

	return useMutation({
		mutationKey: ['dados', 'contato', 'endereço'],
		mutationFn: getMutationFn(id, (id, payload) => participanteApi.postParticipacoesDadosContatoEndereco(id, payload)),
		...mutOptions,
	});
};

/**
 * Hook que gera e gerencia uma mutation relacionada à query de dados de contato.
 *
 * @param id Identificador do recurso.
 * @param options
 * @returns
 */
export const useBeneficiariosMutation: UseMutationByNullableIdFn<readonly GetBeneficiariosReturnType[]> = (
	id,
	options,
) => {
	const kcContext = useKeycloakContext();
	const participanteApi = useParticipanteApi(kcContext, options);
	const mutOptions = useMutationOptions(['beneficiarios'], options);

	return useMutation({
		mutationKey: ['beneficiarios'],
		mutationFn: getMutationFn(id, participanteApi.putBeneficiariosById),
		...mutOptions,
	});
};

/**
 * Hook que gera e gerencia uma mutation relacionada à query do forms de PPE.
 *
 * @param id Identificador do recurso.
 * @param options
 * @returns
 */
export const usePpeMutation: UseMutationByNullableIdFn<GetParticipacoesPpeReturnType> = (id, options) => {
	const kcContext = useKeycloakContext();
	const participanteApi = useParticipanteApi(kcContext, options);
	const mutOptions = useMutationOptions(['ppe'], options);

	return useMutation({
		mutationKey: ['ppe'],
		mutationFn: getMutationFn(id, participanteApi.putParticipacoesPpeById),
		...mutOptions,
	});
};

/**
 * Hook que gera e gerencia uma mutation relacionada à query do forms de US Person.
 *
 * @param id Identificador do recurso.
 * @param options
 * @returns
 */
export const useUsPersonMutation: UseMutationByNullableIdFn<GetParticipacoesUsPersonReturnType> = (id, options) => {
	const kcContext = useKeycloakContext();
	const participanteApi = useParticipanteApi(kcContext, options);
	const mutOptions = useMutationOptions(['us-person'], options);

	return useMutation({
		mutationKey: ['us-person'],
		mutationFn: getMutationFn(id, participanteApi.putParticipacoesUsPersonById),
		...mutOptions,
	});
};

/**
 * Hook que gera e gerencia uma mutation relacionada à query do alteração de custeio.
 *
 * @param id Identificador do recurso.
 * @param options
 * @returns
 */
export const useAlteracaoCusteioPlanoBasicoMutation: UseMutationByNullableIdFn<
	PostContribuicoesAlteracaoCusteioPlanoBasicoPayloadType
> = (id, options) => {
	const kcContext = useKeycloakContext();
	const participanteApi = useParticipanteApi(kcContext, options);
	const mutOptions = useMutationOptions(['contribuicoes'], options);

	return useMutation({
		mutationKey: ['alteracao-custeio'],
		mutationFn: getMutationFn(id, participanteApi.postContribuicoesAlteracaoCusteioPlanoBasicoById),
		...mutOptions,
	});
};

/**
 * Hook que gera e gerencia uma mutation relacionada à query de cálculo de institutos legais.
 *
 * @param id Identificador do recurso.
 * @param options
 * @returns
 */
export const useCalculoInstitutosLegaisMutation: UseMutationWithCustomArgsByNullableIdFn<
	PostCalculoInstitutosLegaisReturnType[],
	PostCalculoInstitutosLegaisArgs
> = (id, options) => {
	const kcContext = useKeycloakContext();
	const participanteApi = useParticipanteApi(kcContext, options);
	const mutOptions = useMutationOptions(['instituto-legal'], options);

	return useMutation({
		mutationKey: ['instituto-legal', id],
		mutationFn: getMutationWithCustomArgsFn(id, (id, payload) =>
			participanteApi.postCalculoInstitutosLegais(id, payload),
		),
		...mutOptions,
	});
};
