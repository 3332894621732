/**
 * Representa os tipos possíveis pro participante
 *
 * Precisa estar de acordo com a API! Mantenha atualizado!
 */
export enum TipoParticipanteEnum {
	// Ativo
	AtivoComEmprestimo = 'Ativo c/ Empréstimo',
	AtivoSemEmprestimo = 'Ativo s/ Empréstimo',

	//BPD
	BPDComEmprestimo = 'BPD c/ Empréstimo',
	BPDSemEmprestimo = 'BPD s/ Empréstimo',

	// Assistido
	RendaFinanceiraProgressivaComEmprestimo = 'Renda Financeira Progressiva c/ Empréstimo',
	RendaFinanceiraProgressivaSemEmprestimo = 'Renda Financeira Progressiva s/ Empréstimo',

	VitalicioComEmprestimo = 'Vitalício c/ Empréstimo',
	VitalicioSemEmprestimo = 'Vitalício s/ Empréstimo',

	PensionistaRegressivo = 'Pensionista Regressivo',
	PensionistaProgressivo = 'Pensionista Progressivo',

	DesligadoComEmprestimo = 'Desligado c/ Empréstimo',
	DesligadoSemEmprestimo = 'Desligado s/ Empréstimo',

	AutopatrocinadoComEmprestimo = 'AutoPatrocinado c/ Empréstimo',
	AutopatrocinadoSemEmprestimo = 'AutoPatrocinado s/ Empréstimo',
}

/**
 * Representa os perfis de investimento
 *
 * Precisa estar de acordo com a API! Mantenha atualizado!
 */
export enum PerfilInvestimentoEnum {
	Conservador = 'Conservador',
	Moderado = 'Moderado',
	Agressivo = 'Agressivo',
	SuperAgressivo = 'Super Agressivo',
}

/**
 * Representa os regimes de tributação possíveis
 *
 * Precisa estar de acordo com a API! Mantenha atualizado!
 */
export enum RegimeTributacaoEnum {
	TabelaProgressiva = 'Tabela Progressiva',
	TabelaRegressiva = 'Tabela Regressiva',
}

/**
 * Representa as situações de participação possíveis
 *
 * Precisa estar de acordo com a API! Mantenha atualizado!
 */
export enum SituacaoParticipacaoEnum {
	Ativo = 'Ativo',
	Falecido = 'Falecido',
}

/**
 * Representa os tipos de telefone possíveis
 *
 * Precisa estar de acordo com a API! Mantenha atualizado!
 */
export enum TipoTelefoneEnum {
	Residencial = 'Residencial',
	Comercial = 'Comercial',
	Celular = 'Celular',
	Recado = 'Recado',
	Outro = 'Outro',
}

/**
 * Representa os tipos de e-mail possíveis
 *
 * Precisa estar de acordo com a API! Mantenha atualizado!
 */
export enum TipoEmailEnum {
	Pessoal = 'EmailPessoal',
	Corporativo = 'EmailCorporativo',
	Comercial = 'EmailComercial',
}

/**
 * Representa os tipos de endereço possíveis
 *
 * Precisa estar de acordo com a API! Mantenha atualizado!
 */
export enum TipoEnderecoEnum {
	Residencial = 'Residencial',
	Comercial = 'Comercial',
	Recado = 'Recado',
}

/**
 * Representa os graus de parentesco possíveis (entre o participante e beneficiários)
 *
 * Precisa estar de acordo com a API! Mantenha atualizado!
 */
export enum GrauDeParentescoEnum {
	FilhoFilha = 'Filho/Filha',
	PaiMae = 'Pai/Mãe',
	IrmaoIrma = 'Irmão/Irmã',
	Conjuge = 'Cônjuge',
	Outro = 'Outro',
}

/**
 * Representa os tipos possíveis de benefício
 *
 * Precisa estar de acordo com a API! Mantenha atualizado!
 */
export enum TipoBeneficioEnum {
	AposentadoriaNormal = 'Aposentadoria normal',
	AposentadoriaAntecipada = 'Aposentadoria antecipada',
	AposentadoriaPorInvalidez = 'Aposentadoria por invalidez',
	PensaoPorMorte = 'Pensão por morte',
	AuxilioDoenca = 'Auxílio-doença',
	AutoPatrocinado = 45,
	Portabilidade = 56,
}

/**
 * Representa os tipos possíveis de renda
 *
 * Precisa estar de acordo com a API! Mantenha atualizado!
 */
export enum TipoRendaEnum {
	RendaVitalicia = 1,
	PrazoCertoAnual = 2,
	PercentualSaldo = 3,
	PagamentoUnico = 4,
	SaqueConcessao = 5,
	RendaRefAnualmente = 6,
	PrazoCerto = 7,
	RendaVitaliciaSemReversao = 8,
	PagamentoUnicoParcelado = 10,
	RendaReais = 11,
	PorFator = 12,
}

type Enumerate<N extends number, Acc extends number[] = []> = Acc['length'] extends N
	? Acc[number]
	: Enumerate<N, [...Acc, Acc['length']]>;

/**
 * Define um intervalo de números (excluindo o limite superior).
 *
 * Não suporta intervalos muito grandes!
 */
export type Range<F extends number, T extends number> = Exclude<Enumerate<T>, Enumerate<F>>;

export const checkObjectForProperty = (obj: object, property: string) => {
	return Object.hasOwn(obj, property);
};

export const checkValueForType = (property: unknown, type: string) => {
	return typeof property === type;
};

export const timer = (ms: number) => new Promise<void>((resolve) => setTimeout(resolve, ms));

export type RecursivePartial<T> = {
	[P in keyof T]?: RecursivePartial<T[P]>;
};

export const getRandomNumber = (min = 0, step = 1) => min + Math.floor(Math.random() * step);

/**
 * Representa o tipo de um item de um array.
 */
export type ArrayItem<T extends readonly unknown[]> = T extends readonly (infer ItemType)[] ? ItemType : never;
