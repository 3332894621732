import { Flex, Surface, Typography } from '@jcm/design-system';
import { memo } from 'react';

import avestruz from 'assets/images/avestruz.svg';

export const Erro = memo(() => {
	return (
		<Surface variant='bright' level={1} style={{ minHeight: 170, padding: '1rem', textAlign: 'center', width: 455 }}>
			<Flex gap={'1rem'} align='center' justify='center' vertical>
				<img src={avestruz} alt='' id='img-avestruz' />
				<Flex vertical>
					<Typography.Title variant='error' size='medium'>
						Ocorreu um erro no processamento.
					</Typography.Title>
					<Typography.Title variant='error' size='medium'>
						Tente novamente.
					</Typography.Title>
				</Flex>
			</Flex>
		</Surface>
	);
});
