import { Icons, Surface, Typography, useTheme } from '@jcm/design-system';
import { memo } from 'react';

export const AlertaMinContribuicao = memo(() => {
	const { colors, shapes } = useTheme();

	return (
		<Surface
			style={{
				width: '100%',
				color: colors.amarelo,
				backgroundColor: '#FFF8F3',
				borderRadius: shapes.sizes.medium,
				padding: `${shapes.sizes.small} ${shapes.sizes.large}`,

				display: 'flex',
				flexDirection: 'row',
				alignItems: 'center',
				gap: shapes.sizes.large,
			}}
		>
			<Icons.Error style={{ fontSize: shapes.sizes.extraLarge }} />
			<Typography.Body>
				A contribuição <Typography.Body style={{ fontWeight: 'bold' }}>adicional</Typography.Body> só é permitida após
				atingir o valor mínimo da contribuição <Typography.Body style={{ fontWeight: 'bold' }}>básica</Typography.Body>.
			</Typography.Body>
		</Surface>
	);
});
