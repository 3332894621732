import { Container, Flex, Typography, useTheme } from '@jcm/design-system';
import { memo } from 'react';

import { useIsMobile } from 'hooks';

interface IDashboardCardProps {
	id?: string;
	className?: string;
	icon?: React.ReactNode;
	title?: React.ReactNode;
	addon?: React.ReactNode;
	children?: React.ReactNode;
	onClick?: React.MouseEventHandler;
}

export const DashboardCard: React.FC<IDashboardCardProps> = memo(({ icon, title, addon, children, ...props }) => {
	const { shapes } = useTheme();
	const isMobile = useIsMobile();

	return (
		<Container
			elevation='lowest'
			variant='default'
			level={1}
			style={{
				height: '100%',
				display: 'flex',
				borderRadius: shapes.sizes.medium,
				userSelect: 'none',
				cursor: props.onClick ? 'pointer' : 'initial',
			}}
			{...props}
		>
			<Flex gap={shapes.sizes.large} flex={1} vertical>
				<Flex justify='space-between' align='center' style={{ userSelect: 'none' }}>
					<Flex gap={shapes.sizes.extraSmall} align='center'>
						<Typography.Title
							size={isMobile ? 'medium' : 'large'}
							variant='default'
							style={{ display: 'flex', fontWeight: isMobile ? 600 : 400 }}
						>
							{icon}
						</Typography.Title>

						<Typography.Body
							size={isMobile ? 'medium' : 'large'}
							variant='default'
							style={{ fontWeight: isMobile ? 600 : 400 }}
						>
							{title}
						</Typography.Body>
					</Flex>

					{addon}
				</Flex>

				{children}
			</Flex>
		</Container>
	);
});
