import { ITableProps, Space, Table } from '@jcm/design-system';

import { ErrorComponent } from 'components';

export interface ICardTabelaProps<T> {
	id?: string;
	className?: string;
	columns: ITableProps<T>['columns'];
	rowKey: ITableProps<T>['rowKey'];
	variant?: ITableProps<T>['variant'];
	dataSource: ITableProps<T>['dataSource'];
	isLoading?: boolean;
	isError?: boolean;
	refetch: () => void;
	rowClassName?: ITableProps<T>['rowClassName'];
}

export const CustomCardTabela = <T,>({
	id,
	className,
	columns,
	rowKey,
	variant = 'default',
	dataSource,
	isLoading,
	isError,
	refetch,
	rowClassName = '',
}: ICardTabelaProps<T>) => {
	return (
		<Space direction='vertical' style={{ width: '100%' }}>
			{isError ? (
				<ErrorComponent refetch={refetch} />
			) : (
				<Table<T>
					id={id}
					className={className}
					variant={variant}
					columns={columns}
					dataSource={dataSource}
					loading={isLoading}
					style={{ width: '100%', borderRadius: '5%' }}
					rowClassName={rowClassName}
					rowKey={rowKey}
					scroll={{ x: true }}
				/>
			)}
		</Space>
	);
};
