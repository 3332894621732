import { Button, Flex, Surface, Typography, useTheme } from '@jcm/design-system';
import { memo } from 'react';

import avestruz from 'assets/images/avestruz.svg';

export type ErrorComponentProps = {
	refetch: () => void;

	className?: string;
	style?: React.CSSProperties;
};
export const ErrorComponent = memo<ErrorComponentProps>(({ refetch, ...props }) => {
	const { shapes } = useTheme();
	return (
		<Flex id='error-component' gap={shapes.sizes.large} align='center' justify='center' vertical {...props}>
			<Surface variant='default' level={1} style={{ width: '100%', textAlign: 'center', padding: '1rem' }}>
				<img src={avestruz} alt='' id='img-avestruz' />
			</Surface>

			<Typography.Title variant='error' size='medium'>
				Algo deu errado!
			</Typography.Title>

			<Button variant='default' type='filled-tonal' onClick={() => refetch()} id='btn-tentar-novamente'>
				Tentar novamente
			</Button>
		</Flex>
	);
});
