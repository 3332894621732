import { Flex, Typography, useTheme } from '@jcm/design-system';
import { memo } from 'react';

import { useIsMobile } from 'hooks';

type PaginaParticipanteProps = {
	id?: string;
	className?: string;
	style?: React.CSSProperties;
	title?: React.ReactNode;
	icon?: React.ReactNode;
	addon?: React.ReactNode;
	children?: React.ReactNode;
};
export const PaginaParticipante = memo<PaginaParticipanteProps>(({ title, icon, addon, children, ...props }) => {
	const { shapes } = useTheme();
	const isMobile = useIsMobile();

	return (
		<Flex gap={shapes.sizes.large} vertical {...props}>
			{!isMobile && (!!title || !!icon) && (
				<Flex gap={shapes.sizes.small} align='center' justify='space-between' style={{ userSelect: 'none' }}>
					<Flex align='center' justify='start' gap={shapes.sizes.small} style={{ height: '40px' }}>
						<Typography.Title
							size='large'
							variant='primary'
							style={{ display: 'flex', alignItems: 'end', fontWeight: 500 }}
						>
							{icon}
						</Typography.Title>

						<Typography.Title size='large' variant='primary' style={{ fontWeight: 500 }}>
							{title}
						</Typography.Title>
					</Flex>

					{addon}
				</Flex>
			)}

			<Flex vertical={isMobile} gap={shapes.sizes.medium} style={{ display: isMobile ? 'flex' : 'block' }}>
				{isMobile && addon}

				<div>{children}</div>
			</Flex>
		</Flex>
	);
});
