import { Flex, Icons, Typography, useTheme } from '@jcm/design-system';
import { nanoid } from 'nanoid';
import { memo, useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { useWindowSize } from 'usehooks-ts';

import { GetParticipantesReturnType } from 'api';
import { useDadosParticipantesFiltradosInfiniteQuery } from 'queries/admin';

import { Card, CardSkeleton, Erro, Header, SemItens } from './filtro';

import styles from './filtro/index.module.scss';

export const TelaParticipante = memo(() => {
	const limit = 20;
	const { ref, inView } = useInView();
	const [termoPesquisado, setTermoPesquisado] = useState<string>('');
	const [termoPesquisadoComAtraso, setTermoPesquisadoComAtraso] = useState<string>('');
	const { colors, shapes } = useTheme();
	const { width } = useWindowSize();

	const { data, fetchNextPage, hasNextPage, status, isFetching, isFetchingNextPage } =
		useDadosParticipantesFiltradosInfiniteQuery({ filtro: termoPesquisadoComAtraso, tamanho: limit });

	useEffect(() => {
		if (inView && hasNextPage && !isFetching) {
			fetchNextPage();
		}
	}, [inView, fetchNextPage, hasNextPage, isFetching]);

	useEffect(() => {
		const timeoutId = setTimeout(() => setTermoPesquisadoComAtraso(termoPesquisado), 750);

		return () => clearTimeout(timeoutId);
	}, [termoPesquisado]);

	const handleTermoPesquisadoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setTermoPesquisado(e.target.value);
	};

	const handleLimparInput = () => {
		setTermoPesquisado('');
	};

	const handleParticipanteClicado = (participante: GetParticipantesReturnType) => {
		const currentUrl = window.location.href.split('/admin')[0];

		window.open(`${currentUrl}/participante?idParticipacao=${participante.participacaoId}`, '_blank');
	};

	const gerarSkeletonCards = () => {
		const quantidadePorLinha = width < 730 ? 1 : width < 1030 ? 2 : width < 1330 ? 3 : width < 1500 ? 4 : 5;
		const itensNaPagina = (data?.pages.length ?? 0) * limit;
		const quantidadeFecharLinha = itensNaPagina % quantidadePorLinha;

		return Array.from(
			{
				length:
					quantidadeFecharLinha === 0
						? quantidadePorLinha
						: quantidadePorLinha + (quantidadePorLinha - quantidadeFecharLinha),
			},
			() => <CardSkeleton key={nanoid()} />,
		);
	};

	return (
		<Flex vertical gap={'0.75rem'} style={{ minHeight: 400 }}>
			<Flex
				vertical
				gap={'0.75rem'}
				style={{
					backgroundColor: colors.surface,
					borderRadius: shapes.sizes.small,
					margin: '0 -1rem',
					padding: '1.5rem 1rem 0 1rem',
					position: 'sticky',
					top: 96,
					zIndex: 1000,
				}}
			>
				<Flex gap={'0.5rem'}>
					<Typography.Title size='large' variant='primary' style={{ fontWeight: 600 }}>
						<Icons.Person variant='outlined' />
					</Typography.Title>
					<Typography.Title size='large' variant='primary' style={{ fontWeight: 600 }}>
						Participantes
					</Typography.Title>
				</Flex>
				<Header
					limparInput={handleLimparInput}
					onInputChange={handleTermoPesquisadoChange}
					valorInput={termoPesquisado}
				/>
			</Flex>
			{status === 'success' ? (
				<>
					<div className={styles.grid}>
						{data.pages.map((page) => (
							<>
								{page.map((p) => {
									return (
										<Card
											key={nanoid()}
											data={{
												cpf: p.cpf,
												empregador: p.empregador,
												nome: p.nome,
												matricula: p.matricula,
												participacaoId: p.participacaoId,
												plano: p.plano,
												tipoVinculoParticipacao: p.tipoVinculoParticipacao,
												situacaoParticipacao: p.situacaoParticipacao,
											}}
											onClick={handleParticipanteClicado}
										/>
									);
								})}
							</>
						))}
						{isFetchingNextPage && gerarSkeletonCards()}
					</div>
					<div ref={ref}></div>
				</>
			) : status === 'error' ? (
				<Flex align='center' justify='center' flex={1}>
					<Erro />
				</Flex>
			) : (
				<Flex align='center' justify='center' flex={1}>
					{isFetching ? (
						<div className={styles.grid} style={{ flex: 1 }}>
							{gerarSkeletonCards()}
						</div>
					) : (
						<SemItens />
					)}
				</Flex>
			)}
		</Flex>
	);
});
