import { DrawerBarItem, FloatButton, Icons, useTheme } from '@jcm/design-system';
import { Outlet } from 'react-router-dom';
import { useDocumentTitle } from 'usehooks-ts';

import { Error, Loading, LoadingSkeleton } from 'components';
import { useIsMobile } from 'hooks';

import { Desktop } from './Desktop';
import { Mobile } from './Mobile';

export interface ILayoutAdminVariantProps {
	id?: string;
	children?: React.ReactNode;

	drawerBarItems: DrawerBarItem[];
}

export const LayoutAdmin: React.FC<{ id?: string }> = ({ id }) => {
	// TODO - Implementar uma query pra trazer dados?
	const isPending = false;
	const isError = false;
	const refetch = () => 0;

	const { colors } = useTheme();

	// Nome da aba
	useDocumentTitle('Portal do Administrador');

	const isMobile = useIsMobile();
	const LayoutComponent: React.FC<ILayoutAdminVariantProps> = isMobile ? Mobile : Desktop;

	const basePath = '/admin';
	const drawerBarItems = [
		{ label: 'Dashboard', key: basePath, icon: <Icons.Home variant='outlined' /> },
		{ label: 'Participantes', key: `${basePath}/participantes`, icon: <Icons.Person variant='outlined' /> },
		{
			label: 'Campanhas',
			key: `${basePath}/campanhas`,
			icon: <Icons.Campaign variant='outlined' />,
			disabled: true,
		},
		{
			label: 'Requerimentos',
			key: `${basePath}/requerimentos`,
			icon: <Icons.ReceiptLong variant='outlined' />,
			disabled: true,
		},
		{
			label: 'Dados Cadastrais',
			key: `${basePath}/dados-cadastrais`,
			icon: <Icons.Badge variant='outlined' />,
			disabled: true,
		},
		{ label: 'SAC', key: `${basePath}/sac`, icon: <Icons.Help variant='outlined' />, disabled: true },
	];

	// TODO - Implementar lógica de controle de acesso nesse arquivo!

	return (
		<>
			<Loading loading={isPending} />

			<LayoutComponent id={id} drawerBarItems={drawerBarItems}>
				<div style={{ padding: '0.5rem 1rem', color: colors.onSurface }}>
					{isPending ? <LoadingSkeleton /> : isError ? <Error refetch={refetch} /> : <Outlet />}

					<FloatButton.BackTop />
				</div>
			</LayoutComponent>
		</>
	);
};
